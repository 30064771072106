import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { FormEngineService } from '../form-engine.service';
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiErrorModule,
  TuiHintModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SectionActivity, SectionValidity, sectionColors } from './section-colors.conf';
import { ComputedFormSection, Form, FormAnswers, QuestionType } from '@pixacare/pxc-ts-core';
import { CommonModule } from '@angular/common';
import {
  TuiCheckboxBlockModule,
  TuiElasticContainerModule,
  TuiFieldErrorPipeModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputSliderModule,
  TuiProgressModule,
  TuiRadioBlockModule,
  TuiRadioListModule,
  TuiTextareaModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import { TuiLetModule } from '@taiga-ui/cdk';

@UntilDestroy()
@Component({
  selector: 'pxc-form',
  templateUrl: './form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiProgressModule,
    TuiLetModule,
    TuiCheckboxBlockModule,
    TuiRadioBlockModule,
    TuiElasticContainerModule,
    TuiRadioListModule,
    TuiInputSliderModule,
    TuiInputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiHintModule,
    TuiTextareaModule,
    TuiToggleModule,
  ],
  providers: [
    FormEngineService,
  ],
})
export class FormComponent implements OnInit {

  @Input() form: Form = this.context.data.form;
  @Input() formAnswers: FormAnswers = this.context.data.formAnswers;
  @Input() readOnly: boolean = this.context.data.readOnly ?? false;

  computedSections: ComputedFormSection[];
  computedSectionsValidity: SectionValidity[] = [];
  computedSectionsColors: string[] = [];

  activeSectionIndex = 0;
  maxSectionIndex = 0;

  QuestionType = QuestionType;
  SectionValidity = SectionValidity;

  formGroup: UntypedFormGroup;

  constructor(
    private readonly formEngineService: FormEngineService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<FormAnswers, Partial<FormComponent>>,
  ) { }

  ngOnInit(): void {
    this.computedSections = this.formEngineService.computeForm(this.form);
    this.formGroup = this.formEngineService.generateAngularForm(this.computedSections, this.formAnswers);
    this.updateSectionsData();
    this.formGroup.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateSectionsData();
    });
  }

  updateSectionsData(): void {
    const colors = [];
    const sectionsValidity: SectionValidity[] = [];

    for (let index = 0; index < this.maxSectionIndex + 1; index++) {
      const sectionValidity = this.computedSections[index].questions.some(
        (question) => this.formGroup.get(question.id).invalid) ? SectionValidity.INVALID : SectionValidity.VALID;
      sectionsValidity.push(sectionValidity);

      const sectionActivity = index > this.activeSectionIndex ? SectionActivity.INACTIVE : SectionActivity.ACTIVE;
      colors.push(sectionColors[sectionValidity][sectionActivity]);
    }

    this.computedSectionsValidity = sectionsValidity;
    this.computedSectionsColors = colors;
  }

  previous(): void {
    if (this.activeSectionIndex === 0) {
      this.context.completeWith(null);
    } else {
      this.activeSectionIndex--;
      this.updateSectionsData();
    }
  }

  next(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAsDirty();
    }

    this.activeSectionIndex++;

    if (this.activeSectionIndex > this.maxSectionIndex) {
      this.maxSectionIndex = this.activeSectionIndex;
    }

    this.updateSectionsData();
  }

  finish(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAsDirty();
      return;
    }

    if (!this.formAnswers && !this.formGroup.touched) {
      this.context.completeWith(null);
      return;
    }

    const answers = this.formEngineService.generateFormAnswers(
      this.formGroup,
      this.computedSections.flatMap((section) => section.questions),
    );

    this.context.completeWith(
      Object.keys(answers).length === 0 ? null : answers,
    );
  }

}
