import { Injectable, inject } from '@angular/core';
import { combineLatest, first, of, switchMap } from 'rxjs';
import { downloadBase64AsImage } from 'src/app/shared/utils/utils';
import { defaultComparisonConfig } from '../gallery/configs';
import { GalleryService } from '../gallery/gallery.service';
import { MediaHttpService } from 'src/app/services/http/media.http.service';
import { selectSequence } from 'src/app/shared/store/sequences/sequences.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { SequencePreviewDrawerComponent } from '../sequence/sequence-preview-drawer/sequence-preview-drawer.component';
import { GalleryItem } from 'src/app/shared/models/gallery-item';
import { sequencesActions } from '@shared/store/sequences/sequences.actions';
import { Media } from '@pixacare/pxc-ts-core';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DrawerGalleryService {

  private readonly galleryService = inject(GalleryService);
  private readonly mediaService = inject(MediaHttpService);
  private readonly store = inject(Store);

  openGallery(medias: Media[]): void {
    combineLatest(medias.map((media) => combineLatest({
      media: this.mediaService.getMedia(media.clientCode, media.id, { fillFullSizeUri: true, fillThumbnailUri: true }),
      sequence: this.store.select(selectSequence(media.sequenceId)).pipe(
        first(),
        switchMap((sequence) => sequence ? of(sequence) : this.fetchMediaSequence(media)),
        untilDestroyed(this),
      ),
    }))).pipe(untilDestroyed(this)).subscribe((sequenceMedias) => {
      this.galleryService.open(
        {
          items: sequenceMedias.map(({ media }): GalleryItem => ({
            thumbnailSrc: media.thumbnailUri,
            pictureSrc: media.uri,
          })),
          actions: [
            {
              icon: 'tuiIconDownloadLarge',
              label: 'Télécharger',
              execute: () => {
                this.download(sequenceMedias.map(({ media }) => media));
              },
            },
          ],
          config: defaultComparisonConfig,
          imageWidgets: sequenceMedias.map(( { sequence }) => ({
            component: SequencePreviewDrawerComponent,
            config: {
              context: {
                patient: sequence.patientInstance,
                date: sequence.sequenceInstance.createdOn,
              },
              dialogClass: 'dialog--gallery-info',
            },
          })),
        },
      ).subscribe();
    });
  }

  download(medias: Media[]): void {
    if (medias.length === 1) {
      this.downloadSingleImage(medias[0].uri);
    } else {
      this.downloadMultipleImage(medias);
    }
  }

  downloadSingleImage(source: string): void {
    downloadBase64AsImage(source, 'mon-image');
  }

  async downloadMultipleImage(
    medias: Media[],
    downloadWidth: number = 1920,
    picturePerRow: number = 3,
    pictureGap: number = 10,
  ): Promise<void> {
    const images = await Promise.all(medias.map((source) => this.uriToImage(source.uri)));
    const sortedImages: HTMLImageElement[][] = images.reduce((acc, thumb, index) => (
      index % picturePerRow === 0
        ? [...acc, images.slice(index, index + picturePerRow)]
        : acc
    ), []);

    let imageHeight = 0;
    const heightPerLine = [];
    sortedImages.forEach((line) => {
      let highestImage = 0;
      line.forEach((image) => {
        const maxWidth = downloadWidth / Math.min(medias.length, picturePerRow);
        const widthRatio = maxWidth / image.width;
        image.width *= widthRatio;
        image.height *= widthRatio;

        highestImage = Math.max(highestImage, image.height);
      });
      imageHeight += highestImage;
      heightPerLine.push(highestImage);
      highestImage = 0;
    });

    const canvas = document.createElement('canvas');
    canvas.height = imageHeight + (heightPerLine.length - 1) * pictureGap;
    canvas.width = downloadWidth + (Math.min(medias.length, picturePerRow) - 1) * pictureGap;

    const context = canvas.getContext('2d');
    let y = 0;
    sortedImages.forEach((line, idx) => {
      let x = 0;
      line.forEach((image) => {
        context.drawImage(image, x, y + (heightPerLine[idx] / 2) - (image.height / 2), image.width, image.height);
        x += image.width + pictureGap;
      });
      y += heightPerLine[idx] + pictureGap;
    });

    downloadBase64AsImage(canvas.toDataURL('image/png'), 'ma-comparaison');
  }

  async uriToImage(uri: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = uri;
      image.onload = () => resolve(image);
      image.onerror = (err) => reject(err);
    });
  }

  private fetchMediaSequence(media: Media) {
    this.store.dispatch(sequencesActions.getUserSequence({
      clientCode: media.clientCode,
      sequenceId: media.sequenceId,
    }));
    return this.store.select(selectSequence(media.sequenceId)).pipe(
      first((sequence) => !!sequence),
    );
  }

}
