import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PatientContact } from '@pixacare/pxc-ts-core';
import { PatientContactHttpService } from '@services/http/patient-contact.http.service';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiDataListModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import {
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiFilterByInputPipeModule,
  TuiStringifyContentPipeModule,
} from '@taiga-ui/kit';
import { BehaviorSubject, combineLatest, first, Observable, shareReplay } from 'rxjs';

type OnChangeFn = (value: PatientContact) => void;

@Component({
  selector: 'pxc-patient-contact-input',
  standalone: true,
  imports: [
    CommonModule,
    TuiLetModule,
    TuiComboBoxModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    FormsModule,
    TuiStringifyContentPipeModule,
    TuiFilterByInputPipeModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PatientContactInputComponent,
    },
  ],
  templateUrl: './patient-contact-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientContactInputComponent implements ControlValueAccessor, OnChanges {

  @Input() patientId: number;
  @Input() clientCode: string;
  @Input() placeholder = 'Sélectionnez ou ajoutez un numéro de téléphone';

  patientContactService = inject(PatientContactHttpService);

  value$ = new BehaviorSubject<PatientContact | null>(null);

  touched = false;
  disabled = false;

  items$: Observable<PatientContact[]>;
  search$ = new BehaviorSubject<string>('');

  onChange: OnChangeFn = () => {};
  onTouched: () => void = () => {};

  ngOnChanges(changes: SimpleChanges): void {

    if (!changes.patientId || !changes.clientCode) {
      return;
    }

    this.items$ = this.patientContactService.getPatientContacts(this.patientId, this.clientCode).pipe(
      shareReplay(1),
    );

    combineLatest([
      this.items$,
      this.value$,
    ]).pipe(first()).subscribe(([items, value]) => {
      if (!items.length || value) {
        return;
      }
      this.onValueChange(items[0]);
    });

  }

  onValueChange(value: PatientContact | null): void {
    this.markAsTouched();
    this.value$.next(value);
    this.onChange(value);
  }

  writeValue(value: PatientContact | null): void {
    this.value$.next(value);
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  stringify(item: PatientContact): string {
    return item.phoneNumber;
  }

  onInput(input: string): void {
    this.value$.pipe(first()).subscribe((value) => {
      if (value?.phoneNumber !== input) {
        this.onValueChange({
          id: null,
          patientId: this.patientId,
          phoneNumber: input ?? '',
        });
      }
    });
  }

  extractValueFromEvent(event: Event): string | null {
    return (event.target as HTMLInputElement)?.value || null;
  }

}
