import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TuiButtonModule, TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { SequencePickerComponent } from '../sequence-picker/sequence-picker.component';
import { CommonModule } from '@angular/common';
import { FlattenedSequence, FlattenedTelemonitoring, SadmEntity } from '@pixacare/pxc-ts-core';

type SequencePickerInput = Pick<SequencePickerComponent,
'clientCode' |
'patientId' |
'departmentIds' |
'telemonitoringIds' |
'disabledSequenceIds' |
'preselectedSequences' |
'singleSelectMode' |
'badgeContent' |
'showTelemonitorings' |
'showSadms'
>;

export type SequencePickerOutput = {
  selectedSequences: FlattenedSequence[];
  selectedSequenceIds: number[];
  selectedTelemonitorings: FlattenedTelemonitoring[];
  selectedSadms: SadmEntity[];
  selectedAllSequences: boolean;
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    SequencePickerComponent,
  ],
  templateUrl: './sequence-picker-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequencePickerDialogComponent {

  clientCode = this.context.data.clientCode;
  patientId = this.context.data.patientId;
  departmentIds = this.context.data.departmentIds ?? [];
  telemonitoringIds = this.context.data.telemonitoringIds ?? [];
  disabledSequenceIds = this.context.data.disabledSequenceIds ?? [];
  preselectedSequences = this.context.data.preselectedSequences ?? [];
  singleSelectMode = this.context.data.singleSelectMode;
  badgeContent = this.context.data.badgeContent;
  showTelemonitorings = this.context.data.showTelemonitorings;
  showSadms = this.context.data.showSadms;

  selectedSequences: FlattenedSequence[] = [];
  selectedSequenceIds: number[] = [];
  selectedTelemonitorings: FlattenedTelemonitoring[] = [];
  selectedSadms: SadmEntity[] = [];
  selectedAllSequences = false;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialogContext<SequencePickerOutput, SequencePickerInput>,
  ) { }

  close(): void {
    this.context.completeWith(null);
  }

  save(): void {
    this.context.completeWith({
      selectedSequences: this.selectedSequences,
      selectedSequenceIds: this.selectedSequenceIds,
      selectedTelemonitorings: this.selectedTelemonitorings,
      selectedSadms: this.selectedSadms,
      selectedAllSequences: this.selectedAllSequences,
    });
  }

}
