<tui-elastic-container *tuiLet="{
  hasPatient: (selectedPatient?.businessIdentifier || selectedPatient?.firstName || selectedPatient?.lastName || selectedPatient?.birthDate),
  isSecondStepDisabled: (!telemonitoringId && departmentIds.length === 0 && clientCode !== 'common'),
  isThirdStepDisabled: ((isPatientEditFormValid$ | async) === false)
} as data">

  <pxc-paywall-alert></pxc-paywall-alert>

  <div class="flex flex-col gap-6" *showIfStorageSpaceAvailable="null">

    <tui-stepper [(activeItemIndex)]="pageIndex" class="justify-between mb-2">
      <button tuiStep>Informations</button>
      <button tuiStep [disabled]="data.isSecondStepDisabled">Patient</button>
      <button tuiStep [disabled]="data.isSecondStepDisabled || data.isThirdStepDisabled">Photographies</button>
    </tui-stepper>
    <div *ngIf="pageIndex === SequenceCreateStep.INFORMATION" class="flex flex-col gap-4">

      <label tuiLabel="Date">
        <tui-input-date-time 
          [(ngModel)]="sequenceDataDate" 
          data-testid="input-date"
          class="flex-1" 
          name="sequenceDate"
          ngDefaultControl
        >
          Entrez une date et une heure
          <input tuiTextfield placeholder="18/04/2019 14:22" />
        </tui-input-date-time>
      </label>

      <p class="text-sm text-slate-700">Emplacement</p>
      <pxc-department-picker
        *ngIf="!telemonitoringId; else telemonitoringSequence"
        [(clientCode)]="clientCode"
        [(departmentIds)]="departmentIds"
        data-testid="department-picker"
      >
        <p 
          *ngIf="!telemonitoringId && departmentIds.length === 0 && clientCode !== 'common'" 
          class="text-red-500 text-center max-md:order-last"
          data-testid="no-department-selected-message"
          error
        >
          Veuillez sélectionner au moins un groupe
        </p>
      </pxc-department-picker>

      <ng-template #telemonitoringSequence>
        <tui-notification status="info" icon="tuiIconInfoLarge">
          <p class="font-bold">Vous créez une séquence rattachée à un télésuivi.</p>
          <p>Elle sera partagée avec les mêmes groupes que son télésuivi.</p>
        </tui-notification>
      </ng-template>

      <div class="flex gap-2 mt-2 md:items-center max-md:flex-col-reverse">

        <button 
          tuiButton 
          appearance="outline" 
          (click)="cancel()" 
          class="md:mr-auto"
        >
          Annuler
        </button>

        <button 
          tuiButton 
          (click)="next()"    
          data-testid="next-page"
          [disabled]="data.isSecondStepDisabled"
        >
          Suivant
        </button>
      </div>

    </div>
    <div *ngIf="pageIndex === SequenceCreateStep.PATIENT" class="flex flex-col gap-2">

      <pxc-title 
        title="Informations patient" 
        titleSize="tiny" 
        icon="tuiIconUserLarge"
      ></pxc-title>

      <pxc-patient-edit 
        [(patient)]="selectedPatient" 
        [clientCode]="clientCode"
        [displayActionButtons]="false" 
        (formValidityUpdate)="isPatientEditFormValid$.next($event)"
        data-testid="input-patient"
        class="px-px"
      ></pxc-patient-edit>

      <div class="flex justify-between gap-2 mt-2 max-md:flex-col-reverse">
        <button tuiButton appearance="outline" (click)="previous()">Précédent</button>
        <button tuiButton [disabled]="data.isSecondStepDisabled || data.isThirdStepDisabled" data-testid="next-page"
          (click)="next()">
          {{ data.hasPatient ? 'Suivant' : 'Ignorer'}}
        </button>
      </div>
    </div>
    <div *ngIf="pageIndex === SequenceCreateStep.PICTURES" class="flex flex-col gap-6" data-testid="step-pictures">

      <div class="flex flex-col gap-2">
        <pxc-title title="Photographies" titleSize="tiny" icon="tuiIconCameraLarge"></pxc-title>
        <pxc-image-input
          [(ngModel)]="sequenceMedias"
          [accept]="dropZoneAcceptValidator"
          data-testid="input-medias"
          ngDefaultControl
        ></pxc-image-input>
      </div>

      <div class="flex flex-col gap-2" *ngIf="data.hasPatient && (displayAnalysis$ | async)">
        <pxc-title title="Questionnaire" titleSize="tiny" icon="heartfulForm"></pxc-title>
        <pxc-sadm-form-input
          [clientCode]="clientCode"
          [patientId]="selectedPatient.id"
          [(sadmEntityId)]="selectedSadmEntityId"
          [(sadmEntity)]="createdSadmEntity"
          [(analysisFormAnswers)]="createdAnalysisFormAnswers"
          [(protocolFormAnswers)]="createdProtocolFormAnswers"
        ></pxc-sadm-form-input>
      </div>

      <div class="flex flex-col gap-2">
        <pxc-title title="Mots clés" titleSize="tiny" icon="tuiIconTagLarge"></pxc-title>
        <pxc-select-label 
          [(selectedLabels)]="selectedLabels" 
          [showFavoriteLabels]="true"
          data-testid="input-labels"
        ></pxc-select-label>
      </div>

      <div class="flex flex-col gap-2">
        <pxc-title title="Description" titleSize="tiny" icon="tuiIconFileTextLarge"></pxc-title>
        <tui-textarea 
          [(ngModel)]="description" 
          [expandable]="true" 
          maxlength="65535" 
          data-testid="input-description"
          name="sequenceDescription" 
          ngDefaultControl
        >
          Ajoutez une description
        </tui-textarea>
      </div>

      <div class="flex justify-between gap-2 mt-2 max-md:flex-col-reverse">
        <button tuiButton appearance="outline" (click)="previous()">Précédent</button>
        <button tuiButton (click)="create()" data-testid="create-sequence">Ajouter</button>
      </div>
    </div>

  </div>

</tui-elastic-container>