import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, first, map, of, switchMap, withLatestFrom } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { AdministrationUser } from 'src/app/shared/models/administration-user';
import { selectDepartmentUsers } from 'src/app/shared/store/users/users.selectors';
import { UserAdminState } from 'src/app/shared/models/enums/user-admin-state.enum';
import { selectDepartmentMemberships } from 'src/app/shared/store/departments/departments.selectors';
import { isDateFromFuture, isDateFromPast } from 'src/app/shared/utils/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { departmentsActions } from 'src/app/shared/store/departments/departments.actions';
import { ClientAddUserComponent } from '../../dashboard/client-add-user/client-add-user.component';
import { usersActions } from 'src/app/shared/store/users/users.actions';
import {
  DepartmentAdminService,
} from '../department-admin/department-admin.service';
import { ClientUser, DepartmentMembership, LicenseState } from '@pixacare/pxc-ts-core';

@UntilDestroy()
@Injectable()
export class DepartmentUsersAdminService {

  private departmentUsers$: Observable<AdministrationUser[]>;

  private readonly store = inject(Store);
  private readonly dialogService = inject(DialogService);
  private readonly departmentAdministrationService = inject(DepartmentAdminService);


  loadDepartmentAdministrationUsers$(): Observable<AdministrationUser[]> {
    this.departmentUsers$ = this.departmentAdministrationService.params$.pipe(
      switchMap(({ clientCode, departmentId }) =>
        combineLatest([
          this.store.select(selectDepartmentUsers(clientCode, departmentId)),
          this.store.select(selectDepartmentMemberships(clientCode, departmentId)),
        ]).pipe(
          map(([clientUsers, memberships]) => clientUsers.map((clientUser) => ({
            ...clientUser,
            state: this.getUserState(clientUser, memberships[clientUser.id]),
            isAdmin: memberships[clientUser.id]?.isAdmin,
          }))),
        ),
      ));
    return this.departmentUsers$;
  }

  enableUser(user: AdministrationUser): void {
    this.updateUserState(
      user.id,
      { isAdmin: user.isAdmin, enableUser: true },
      `Êtes-vous certain de vouloir ajouter ${user.firstName} ${user.lastName} au groupe ?`,
      'Ajouter l\'utilisateur',
    );
  }

  disableUser(user: AdministrationUser): void {
    this.updateUserState(
      user.id,
      { isAdmin: user.isAdmin, disableUser: true },
      `Êtes-vous certain de vouloir retirer ${user.firstName} ${user.lastName} de ce groupe ?`,
      'Retirer l\'utilisateur',
    );
  }


  adminChange(user: AdministrationUser): void {
    this.updateUserState(
      user.id,
      { isAdmin: !user.isAdmin },
    );
  }

  removeUser(user: AdministrationUser): void {

    const displayedName = user.state === UserAdminState.INVITED
      ? user.mailAddress
      : `${user.firstName} ${user.lastName}`;

    combineLatest([
      this.departmentAdministrationService.params$,
      this.dialogService.openConfirm(
        `Êtes-vous certain de vouloir retirer ${displayedName} de ce groupe ?`,
        {
          yes: 'Retirer l\'utilisateur',
        },
      ),
    ]).pipe(first())
      .subscribe(([{ clientCode, departmentId }, confirm]) => {
        if (confirm) {
          this.store.dispatch(
            departmentsActions.removeDepartmentUser({
              clientCode,
              departmentId,
              userId: user.id,
            }),
          );
        }
      });
  }

  addUsers(): void {
    combineLatest([
      this.departmentAdministrationService.params$,
      this.dialogService.openComponent<ClientAddUserComponent, string[]>(
        ClientAddUserComponent,
        {
          label: 'Ajouter des utilisateurs',
        },
      ),
    ]).pipe(first()).subscribe(([{ clientCode, departmentId }, mailAddresses]) => {
      if (mailAddresses && mailAddresses.length > 0) {
        this.store.dispatch(
          usersActions.addUsers({
            clientCode,
            departmentId,
            mailAddresses,
          }),
        );
      }
    });
  }

  private updateUserState(
    userId: number,
    {
      isAdmin,
      disableUser,
      enableUser,
    }: { isAdmin: boolean; disableUser?: boolean; enableUser?: boolean },
    confirmLabel?: string,
    confirmYesLabel?: string,
  ): void {
    this.departmentAdministrationService.params$.pipe(
      first(),
      switchMap(({ clientCode, departmentId }) =>
        combineLatest([
          this.store.select(selectDepartmentMemberships(clientCode, departmentId)),
          confirmLabel && confirmYesLabel ? this.dialogService.openConfirm(
            confirmLabel,
            {
              yes: confirmYesLabel,
            },
          ) : of(true),
        ]).pipe(first(), withLatestFrom(of(clientCode), of(departmentId))),
      ),
    ).subscribe(([[memberships, confirm], clientCode, departmentId]) => {
      if (confirm) {
        const newDisabledOn = disableUser ? new Date() : (enableUser ? null : memberships[userId].disabledOn);
        this.store.dispatch(
          departmentsActions.updateDepartmentUser({
            clientCode,
            departmentId,
            departmentUserId: userId,
            membership: {
              isAdmin,
              disabledOn: newDisabledOn,
            },
          }),
        );
      }
    });
  }

  private getUserState(clientUser: ClientUser, membership: DepartmentMembership): UserAdminState {
    if (clientUser.licenseState === LicenseState.INVITED) {
      return UserAdminState.INVITED;
    }
    if (isDateFromFuture(new Date(membership.disabledOn))) {
      return UserAdminState.ENABLED;
    }
    if (isDateFromPast(new Date(membership.disabledOn))) {
      return UserAdminState.DISABLED;
    }
  }

}
