<div class="flex flex-col gap-4">

    <pxc-title
        title="Ajouter des collaborateurs"
        titleSize="tiny"
        subtitle="Les utilisateurs avec lesquels vous choisissez de partager ce télésuivi pourront accéder aux photographies du patient et participer à la conversation associée."
        data-testid="title"
    ></pxc-title>

    <pxc-user-input 
        [allowEmails]="false"
        [searchUsers]="bindedSearchUsers"
        [disabled]="disabled"
        [ngModel]="userInputValue"
        (ngModelChange)="onSelectChange($event)"
        [placeholder]="placeholder"
        [excludedIds]="excludedIds"
        data-testid="input"
        ngDefaultControl
    ></pxc-user-input>

    <div>
        <p class="font-medium text-slate-700 text-sm mb-2">Collaborateurs</p>
        <tui-scrollbar class="max-h-56 min-h-24" *ngIf="(users$ | async) as users">
            <div class="flex flex-col gap-3">
                <div class="flex gap-2 items-center" *ngFor="let item of value; index as idx">
                    <pxc-user-card
                        [user]="item.user"
                        class="flex-1"
                        [attr.data-testid]="'user-card-'+item.user.id"
                    ></pxc-user-card>
                    <button
                        *ngIf="item.user.id !== currentUserId"
                        tuiIconButton
                        (click)="removeUser(idx)"
                        icon="tuiIconClose"
                        appearance="ghost"
                        [attr.data-testid]="'remove-user-'+item.user.id"
                    ></button>
                </div>
            </div>
        </tui-scrollbar>
    </div>
</div>