import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TUI_DATE_SEPARATOR } from '@taiga-ui/cdk';
import {
  TUI_ICONS,
  TUI_SANITIZER,
  TuiAlertModule,
  TuiDialogModule,
  TuiRootModule,
  tuiButtonOptionsProvider,
  tuiCheckboxOptionsProvider,
} from '@taiga-ui/core';
import {
  TUI_DATE_TIME_VALUE_TRANSFORMER,
  TUI_DATE_VALUE_TRANSFORMER,
  TUI_PROMPT_WORDS,
  TUI_VALIDATION_ERRORS,
  tuiTagOptionsProvider,
} from '@taiga-ui/kit';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import player, { LottiePlayer } from 'lottie-web/build/player/lottie_svg';
import { provideLottieOptions } from 'ngx-lottie';
import { of } from 'rxjs';
import { icons } from 'src/assets/icons/icons';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './helpers/global-error-handler';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { AuthenticationService } from './services/authentication.service';
import { AppConfigHttpService } from './services/http/app-config.http.service';
import { FeaturesStoreModule } from './shared/store/store.module';
import { initializerConfigFn } from './shared/utils/app-initialization';
import { NavigationService } from './services/navigation.service';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { HttpResponseDateInterceptor } from './shared/interceptors/http-response-date.interceptor';
import { HttpServerUnavailableInterceptor } from './shared/interceptors/http-server-unavailable.interceptor';
import { DateTransformer } from './shared/utils/date-transformer';
import { DateTimeTransformer } from './shared/utils/date-time-transformer';
import { TUI_FRENCH_LANGUAGE, TUI_LANGUAGE } from '@taiga-ui/i18n';
import { UnauthorizedInterceptor } from '@shared/interceptors/unauthorized.interceptor';
import { RouterModule } from '@angular/router';
import { validationErrorFactory } from '@shared/utils/validation-errors-factory';

export const playerFactory = (): LottiePlayer => player;

export const appInsightsFactory = (): ApplicationInsights => (
  new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
    },
  })
);

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthenticationModule,
    DashboardModule,
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    FeaturesStoreModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    RouterModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializerConfigFn,
      multi: true,
      deps: [AppConfigHttpService, AuthenticationService, NavigationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseDateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServerUnavailableInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: ApplicationInsights,
      useFactory: appInsightsFactory,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: TUI_SANITIZER,
      useClass: NgDompurifySanitizer,
    },
    {
      provide: TUI_ICONS,
      useValue: icons,
    },
    {
      provide: TUI_LANGUAGE,
      useValue: of(TUI_FRENCH_LANGUAGE),
    },
    {
      provide: TUI_DATE_TIME_VALUE_TRANSFORMER,
      useClass: DateTimeTransformer,
    },
    {
      provide: TUI_DATE_VALUE_TRANSFORMER,
      useClass: DateTransformer,
    },
    {
      provide: TUI_DATE_SEPARATOR,
      useValue: '/',
    },
    {
      provide: TUI_PROMPT_WORDS,
      useValue: of({
        yes: 'Oui',
        no: 'Non',
      }),
    },
    {
      provide: TUI_VALIDATION_ERRORS,
      deps: [AppConfigHttpService],
      useFactory: validationErrorFactory,
    },
    tuiButtonOptionsProvider({
      size: 'm',
      shape: 'rounded',
    }),
    tuiTagOptionsProvider({
      status: 'primary',
      size: 'm',
    }),
    tuiCheckboxOptionsProvider({
      size: 'l',
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideLottieOptions({
      player: playerFactory,
    }),
  ],
})
export class AppModule {}
