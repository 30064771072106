<pxc-sequence-picker
    [clientCode]="clientCode"
    [patientId]="patientId"
    [departmentIds]="departmentIds"
    [telemonitoringIds]="telemonitoringIds"
    [disabledSequenceIds]="disabledSequenceIds"
    [preselectedSequences]="preselectedSequences"
    [singleSelectMode]="singleSelectMode"
    [badgeContent]="badgeContent"
    [showTelemonitorings]="showTelemonitorings"
    [showSadms]="showSadms"
    (selectedAllSequencesChange)="selectedAllSequences = $event"
    (selectedSequencesChange)="selectedSequences = $event"
    (selectedSequenceIdsChange)="selectedSequenceIds = $event"
    (selectedSadmsChange)="selectedSadms = $event"
    (selectedTelemonitoringsChange)="selectedTelemonitorings = $event"
    data-testid="sequence-picker"
></pxc-sequence-picker>

<div class="flex gap-2 items-center justify-between mt-2 flex-wrap">
    <button 
        tuiButton 
        type="button" 
        appearance="outline" 
        (click)="close()" 
        class="max-md:w-full"
        data-testid="cancel"
    >
        Annuler
    </button>
    <button 
        tuiButton 
        type="button" 
        class="max-md:w-full max-md:order-first" 
        (click)="save()" 
        data-testid="confirm"
    >
        Enregistrer
    </button>
</div>