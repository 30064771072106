import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  FlattenedSequence, PaginationOptions, Media, Sequence, PublicUser, PagedResponse,
} from '@pixacare/pxc-ts-core';
import { AuthenticationService } from '@services/authentication.service';
import { MediaHttpService } from '@services/http/media.http.service';
import { SequenceHttpService } from '@services/http/sequence.http.service';
import { Observable, mergeMap, switchMap, catchError, filter, concatMap, concat, of } from 'rxjs';
import { clientsActions } from '../clients/clients.actions';
import { labelsActions } from '../labels/labels.actions';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { notificationsActions } from '../notifications/notifications.actions';
import { patientsActions } from '../patients/patients.actions';
import { sadmActions } from '../sadm/sadm.actions';
import { usersActions } from '../users/users.actions';
import { sequencesActions } from './sequences.actions';


const handlePreviousNavigation = (
  collection: PagedResponse<FlattenedSequence>,
  clientCode: string,
  options?: PaginationOptions,
) => [
  sequencesActions.loadSequencesEntities({
    clientCode,
    reset: options?.reset,
    collection,
  }),
  sequencesActions.getPreviousUserSequencesSuccess({
    clientCode,
    collection,
  }),
];

const handleNextNavigation = (
  collection: PagedResponse<FlattenedSequence>,
  clientCode: string,
  options?: PaginationOptions,
) => [
  sequencesActions.loadSequencesEntities({ clientCode, collection }),
  sequencesActions.getNextUserSequencesSuccess({
    clientCode,
    reset: options?.reset,
    collection,
  }),
];

@Injectable()
export class SequencesEffects {

  getUserSequence$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.getUserSequence),
      mergeMap(({ clientCode, sequenceId }) =>
        this.sequenceService.getSequence(clientCode, sequenceId, {
          fillFullSizeUri: true,
          fillThumbnailUri: true,
        })
          .pipe(
            switchMap((sequence: FlattenedSequence) => [
              sequencesActions.loadSequenceEntities({ sequence, clientCode }),
              sequencesActions.updateFlattenedSequenceSuccess({ clientCode, sequence }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  getPreviousUserSequences$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.getPreviousUserSequences),
      mergeMap(({ clientCode, options }) =>
        this.sequenceService.getSequences(clientCode, options)
          .pipe(
            switchMap((collection: PagedResponse<FlattenedSequence>) =>
              handlePreviousNavigation(collection, clientCode, options)),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  getNextUserSequences$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.getNextUserSequences),
      mergeMap(({ clientCode, options }) =>
        this.sequenceService.getSequences(clientCode, options)
          .pipe(
            switchMap((collection: PagedResponse<FlattenedSequence>) =>
              handleNextNavigation(collection, clientCode, options),
            ),
            catchError((error: HttpErrorResponse) => [
              lifeCycleActions.loadError({ error }),
              ...(error.status === HttpStatusCode.Forbidden ? [
                notificationsActions.error({
                  title: 'Les séquences ne peuvent pas être chargées sur ce client.',
                  message: 'Vous n\'avez pas accès aux séquences de ce client.',
                })] : []),
            ]),
          ),
      ),
    ),
  );

  updateSequenceMedia$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.updateSequenceMedia),
      filter(({ mediaId }) => !!mediaId),
      mergeMap(({
        clientCode, sequenceId, mediaId,
      }) => this.mediaService.getMedia(clientCode, mediaId, {
        fillFullSizeUri: true,
        fillThumbnailUri: true,
      })
        .pipe(
          switchMap((updatedMedia: Media) => [
            sequencesActions.updateSequenceMediaSuccess({ sequenceId, updatedMedia }),
          ]),
          catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
        ),
      ),
    ),
  );

  updateSequenceLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.updateSequenceLabels),
      mergeMap(({
        clientCode,
        userId,
        sequenceId,
        existingLabels,
        createdLabels,
      }) =>
        this.sequenceService.updateSequenceLabels(clientCode, userId, sequenceId, existingLabels, createdLabels)
          .pipe(
            switchMap((sequence: FlattenedSequence) => [
              labelsActions.addLabels({
                labels: sequence.labels,
                isUsedByUser: sequence.createdByInstance?.id === userId,
              }),
              sequencesActions.updateFlattenedSequenceSuccess({
                clientCode: sequence.clientCode,
                sequence,
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  synchronizeSequence$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.synchronizeSequence),
      mergeMap((payload) =>
        this.sequenceService.createSequence(payload.data)
          .pipe(
            switchMap((sequence: FlattenedSequence) => [
              notificationsActions.success({
                title: 'Séquence synchronisée.',
                message: 'Votre séquence de photographies est désormais sécurisée.',
              }),
              sequencesActions.synchronizeSequenceSuccess({
                clientCode: payload.data.get('clientCode').toString(),
                sequence,
              }),
              labelsActions.addLabels({
                labels: sequence.labels,
                isUsedByUser: true,
              }),
              ...(sequence.patientInstance
                ? [patientsActions.updatePatientSuccess({
                  clientCode: payload.data.get('clientCode').toString(),
                  patient: sequence.patientInstance,
                })] : []),
              clientsActions.updateDataConsumption({
                clientCode: payload.data.get('clientCode').toString(),
                sequence,
              }),
              ...(sequence.sequenceInstance.sadmEntityId
                ? [
                  sadmActions.unloadSadmDashboard({
                    sadmEntityId: sequence.sequenceInstance.sadmEntityId,
                  }),
                  sadmActions.getSadmEntity({ sadmEntityId: sequence.sequenceInstance.sadmEntityId }),
                ]
                : []),
            ]),
            catchError((error: Error) => [
              sequencesActions.synchronizeSequenceError({ error }),
              lifeCycleActions.loadError({ error }),
              notificationsActions.error({
                title: 'Impossible de créer la séquence.',
                message: 'Une erreur s\'est produite.',
              }),
            ]),
          ),
      ),
    ),
  );

  addSequenceMedia$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.addSequenceMedia),
      mergeMap(({ clientCode, sequenceId, mediaId }) => this.mediaService.getMedia(clientCode, mediaId, {
        fillFullSizeUri: true,
        fillThumbnailUri: true,
      })
        .pipe(
          switchMap((updatedMedia: Media) => [
            sequencesActions.addSequenceMediaSuccess({ sequenceId, updatedMedia }),
          ]),
          catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
        ),
      ),
    ),
  );

  patchSequence$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.patchSequence),
      mergeMap(({ sequence, clientCode }) =>
        this.sequenceService.patchSequence(clientCode, sequence)
          .pipe(
            switchMap((updatedSequence: Sequence) => [
              sequencesActions.updateSequenceSuccess({
                clientCode,
                updatedSequence,
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  updateSequencePatient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.updateSequencePatient),
      mergeMap(({ clientCode,
        sequenceId,
        patient,
      }) =>
        this.sequenceService.updateSequencePatient(clientCode, sequenceId, patient)
          .pipe(
            switchMap((sequence: FlattenedSequence) => [
              sequencesActions.updateFlattenedSequenceSuccess({
                clientCode,
                sequence,
              }),
              patientsActions.updatePatientSuccess({
                clientCode,
                patient: sequence.patientInstance,
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  moveSequence$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.moveSequence),
      concatMap(({
        fromClientCode,
        toClientCode,
        sequenceId,
        overridePatient,
      }) => concat(
        of(
          notificationsActions.info({
            title: 'Nous transférons vos données de façon sécurisée.',
          }),
        ),
        this.sequenceService.moveSequence(fromClientCode, toClientCode, sequenceId, overridePatient)
          .pipe(
            switchMap(() => [
              sequencesActions.deleteSequenceSuccess({ sequenceId }),
              notificationsActions.success({
                title: 'Vos données ont été correctement déplacées.',
                message: 'Séquence deplacée',
              }),
            ]),
            catchError((error: Error) => [
              lifeCycleActions.loadError({ error }),
              notificationsActions.error({
                title: "La séquence n'a pas été déplacée, merci de réessayer plus tard.",
                message: 'Erreur lors du déplacement',
              }),
            ]),
          ),
      ))),
  );

  deleteSequence$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.deleteSequence),
      concatMap((payload) => concat(
        of(notificationsActions.info({
          title: 'La séquence est en cours de suppression.',
        })),
        this.sequenceService.deleteSequence(
          payload.clientCode,
          payload.sequenceId,
        ).pipe(
          switchMap(() => [
            sequencesActions.deleteSequenceSuccess({ sequenceId: payload.sequenceId }),
            notificationsActions.success({
              title: 'La séquence a bien été supprimée.',
              message: 'Votre séquence a été supprimée avec succès.',
            }),
          ]),
          catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
        ),
      ),
      ),
    ));

  attachSequenceMedias$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.attachSequenceMedias),
      mergeMap(({ clientCode, sequenceId, medias }) =>
        this.sequenceService.attachSequenceMedias(
          clientCode, sequenceId, medias,
        ).pipe(
          switchMap((sequence: FlattenedSequence) => [
            sequencesActions.attachSequenceMediasSuccess({ updatedSequence: sequence }),
          ]),
          catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
        ),
      ),
    ),
  );

  deleteSequenceMedia$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.deleteSequenceMedia),
      mergeMap(({ clientCode, sequenceId, mediaId }) =>
        this.sequenceService.deleteSequenceMedia(clientCode, sequenceId, mediaId)
          .pipe(
            switchMap(() => [
              sequencesActions.deleteSequenceMediaSuccess({ sequenceId, mediaId }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  loadSequences$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.loadSequences),
      mergeMap(({ data }) => [
        sequencesActions.loadSequencesEntities({
          clientCode: null,
          collection: {
            data,
          } as unknown as PagedResponse<FlattenedSequence>,
        }),
      ]),
    ));

  loadSequenceEntities$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.loadSequenceEntities),
      mergeMap(({ sequence }) => [
        patientsActions.updatePatientsSuccess({
          clientCode: sequence.clientCode,
          patients: [sequence.patientInstance],
        }),
        labelsActions.addLabels({
          labels: sequence.labels,
          isUsedByUser: sequence.createdByInstance?.id === this.authenticationService.currentUser.id,
        }),
        ...(!!sequence.createdByInstance ? [usersActions.updateUsers({
          clientCode: sequence.clientCode,
          users: [sequence.createdByInstance as PublicUser],
        })] : []),
      ])),
  );


  loadSequencesEntities$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.loadSequencesEntities),
      mergeMap(({ clientCode, collection }) => {

        const actions = [];
        if (clientCode !== null) {

          const sequenceUsers = collection.data
            .filter((sequence) => !!sequence.createdByInstance)
            .map((sequence) => sequence.createdByInstance as PublicUser);

          if (sequenceUsers && sequenceUsers.length > 0) {
            actions.push(usersActions.updateUsers({
              clientCode,
              users: sequenceUsers,
            }));
          }
          actions.push(patientsActions.updatePatientsSuccess({
            clientCode,
            patients: collection.data.map((sequence) => sequence.patientInstance),
          }));
        } else {
          collection.data.forEach((sequence) => {
            if (!!sequence.createdByInstance) {
              actions.push(usersActions.updateUsers({
                clientCode: sequence.clientCode,
                users: [sequence.createdByInstance as PublicUser],
              }));
            }
            actions.push(patientsActions.updatePatientSuccess({
              clientCode: sequence.clientCode,
              patient: sequence.patientInstance,
            }));
          });
        }

        return [
          ...collection.data.map((sequence) => labelsActions.addLabels({
            labels: sequence.labels,
            isUsedByUser: sequence.createdByInstance?.id === this.authenticationService.currentUser.id,
          })),
          ...actions,
        ];
      }),
    ),
  );

  addSequenceAnalysis$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.addSequenceAnalysis),
      mergeMap((payload) =>
        this.sequenceService.addSequenceAnalysis(payload).pipe(
          switchMap((sequence: Sequence) => [
            sequencesActions.updateSequenceSuccess({
              clientCode: payload.clientCode,
              updatedSequence: sequence,
            }),
          ]),
        ),
      ),
    ),
  );

  editSequenceAnalysis$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.editSequenceAnalysis),
      mergeMap((payload) =>
        this.sequenceService.editSequenceAnalysis(payload).pipe(
          switchMap((sequence: Sequence) => [
            sequencesActions.updateSequenceSuccess({
              clientCode: payload.clientCode,
              updatedSequence: sequence,
            }),
            sadmActions.unloadSadmDashboard({
              sadmEntityId: sequence.sadmEntityId,
            }),
          ]),
        ),
      ),
    ),
  );

  deleteSequenceAnalysis$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.deleteSequenceAnalysis),
      mergeMap((payload) =>
        this.sequenceService.deleteSequenceAnalysis(payload).pipe(
          switchMap((sequence: Sequence) => [
            sequencesActions.updateSequenceSuccess({
              clientCode: payload.clientCode,
              updatedSequence: sequence,
            }),
            sadmActions.unloadSadmDashboard({
              sadmEntityId: sequence.sadmEntityId,
            }),
          ]),
        ),
      ),
    ),
  );

  getSequenceAnalysis$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sequencesActions.getSequenceAnalysis),
      mergeMap((payload) =>
        this.sequenceService.getSequence(payload.clientCode, payload.sequenceId, {
          fillThumbnailUri: true,
          forms: true,
        }).pipe(
          switchMap((sequence: FlattenedSequence) => [
            sequencesActions.updateSequenceSuccess({
              clientCode: payload.clientCode,
              updatedSequence: sequence.sequenceInstance,
            }),
          ]),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sequenceService: SequenceHttpService,
    private readonly mediaService: MediaHttpService,
    private readonly authenticationService: AuthenticationService,
  ) {}

}
