<tui-elastic-container>
  <div *ngIf="step === 1" class="flex flex-col">
    <pxc-sequence-picker
      [patientId]="patientId"
      [clientCode]="clientCode"
      [departmentIds]="departmentIds"
      [telemonitoringIds]="telemonitoringId ? [telemonitoringId] : null"
      [preselectedSequences]="selectedSequences"
      (selectedAllSequencesChange)="isAllSequencesSelected = $event"
      (selectedSequenceIdsChange)="selectedSequenceIds = $event"
      (selectedSequencesChange)="selectedSequences = $event"
      (selectedTelemonitoringIdsChange)="telemonitoringsSelectionChanged($event)"
      (selectedSadmsChange)="sadmEntitiesSelectionChanged($event)"
      [showSadms]="true"
      [showTelemonitorings]="true"
      data-testid="sequence-picker"
    ></pxc-sequence-picker>
  </div>
  
  <div *ngIf="step === 2" data-testid="summary" class="
    flex flex-col gap-4 pb-4
  ">

    <p>Vérifiez les informations qui seront incluses dans le rapport.</p>
    <pxc-patient-card
       [patient]="patient$ | async"
    ></pxc-patient-card>
    <div class="flex flex-col gap-1">
      <pxc-title
        title="Inclus dans le rapport"
        titleSize="tiny"
        icon="packageCheck"
      ></pxc-title>
      <div class="flex flex-col gap-2 ml-8">
        <pxc-sequence-stacks-preview
          [sequences]="selectedSequences"
          (mediaClicked)="openGallery($event)"
        ></pxc-sequence-stacks-preview>
        <div class="flex flex-col gap-1">
          <ng-container
            [ngTemplateOutlet]="dataLabel"
            [ngTemplateOutletContext]="{
              icon: 'tuiIconCamera',
              label: selectedSequenceIds.length | i18nPlural : sequencesCountPluralMapping
            }"></ng-container>
            <ng-container *ngFor="let telemonitoring of selectedTelemonitoringNames"
              [ngTemplateOutlet]="dataLabel"
              [ngTemplateOutletContext]="{
                icon: 'telemonitoring',
                label: telemonitoring
              }"></ng-container>
            <ng-container *ngFor="let sadmEntity of selectedSadmEntityNames"
              [ngTemplateOutlet]="dataLabel"
              [ngTemplateOutletContext]="{
                icon: 'analysis',
                label: sadmEntity
              }"></ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="selectedSadmEntityNames.length > 0" class="flex flex-col gap-2">
      <pxc-title 
        title="Personnalisation du rapport"
        titleSize="tiny"
        icon="magicWand"
      ></pxc-title>
      <form [formGroup]="customReportForm" class="ml-10 flex flex-col gap-2" class="flex flex-col gap-2">
        <tui-checkbox-labeled formControlName="includeReports">
          Afficher les réponses aux questionnaires
        </tui-checkbox-labeled>
        <tui-checkbox-labeled formControlName="includeCharts">
          Afficher les graphiques
        </tui-checkbox-labeled>
      </form>
    </div>
  </div>
</tui-elastic-container>

<div class="
  -mx-8 -mb-8 px-8 py-4 bg-white rounded-b-2xl
  flex flex-col gap-6 border-t-2 border-slate-100
">
  
  <div class="flex max-md:flex-col justify-stretch md:justify-between gap-2">
    <button tuiButton
      status="primary"
      type="button"
      appearance="outline"
      (click)="previousStep()"
      data-testid="cancel-selection-button"
    >
      {{ step === 2 ? "Retour" : "Annuler" }}
    </button>
    <div class="flex max-md:flex-col items-stretch md:items-center gap-4 max-md:order-first">
      <p *ngIf="step === 1" class="text-center">{{ selectedSequenceIds.length | i18nPlural: sequenceCountPluralMapping }}</p>
      <button tuiButton
        type="button"
        [disabled]="selectedSequenceIds.length <= 0"
        (click)="nextStep()"
        data-testid="confirm-selection-button"
      >
        {{ step === 2 ? "Exporter" : "Suivant" }}
      </button>
    </div>
  </div>
  
  <ng-template let-icon="icon" let-label="label" #dataLabel>
    <div *ngIf="label" class="flex items-center gap-3">
      <tui-svg [src]="icon"></tui-svg>
      <p>{{label}}</p>
    </div>
  </ng-template>
</div>