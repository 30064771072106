import { Injectable } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { LogoutStrategyService } from '@services/logout-strategy.service';


@Injectable({
  providedIn: 'root',
})
export class MustChangePasswordGuard  {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly logoutStrategyService: LogoutStrategyService,
  ) {}

  canActivate(): boolean {
    if (
      !!this.authenticationService.currentUser
      && !this.authenticationService.currentUser.mustChangePassword
    ) {
      return true;
    }
    this.logoutStrategyService.logout();
    return false;
  }

}
