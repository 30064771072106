import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { Telemonitoring } from '@pixacare/pxc-ts-core';
import { TuiAlertService, TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TelemonitoringHttpService } from 'src/app/services/http/telemonitoring.http.service';
import { AutoCompleteModel } from 'src/app/shared/models/helpers/auto-complete-model';

@Component({
  templateUrl: './telemonitoring-send-tutorial.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringSendTutorialComponent {

  telemonitoring: Telemonitoring = this.context.data.telemonitoring;

  validators: Validators = [Validators.email];
  mailAddresses: AutoCompleteModel[] = [];

  constructor(
    private telemonitoringService: TelemonitoringHttpService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, {
      telemonitoring: Telemonitoring;
    }>,
  ) { }

  sendTutorial(): void {
    setTimeout(() => {

      this.close();

      if (this.mailAddresses.length === 0) {
        return;
      }

      const loadingToastr = this.alertService.open('Le tutoriel est en cours d\'envoi aux adresses sélectionnées.', {
        label: 'Envoi en cours ...',
        status: 'info',
        hasCloseButton: false,
        autoClose: 10000,
      }).subscribe();

      this.telemonitoringService.sendTutorial(
        this.telemonitoring.id,
        this.mailAddresses.map((address) => address.value),
      ).subscribe({
        next: () => {
          this.alertService.open('Les destinataires ont bien reçu le tutoriel de télésuivi.', {
            label: 'Tutoriel envoyé',
            status: 'success',
          }).subscribe();
        },
        error: () => {
          this.alertService.open('Quelque chose s\'est mal passé, merci de réessayer dans quelques instants.', {
            label: 'Oups !',
            status: 'error',
          }).subscribe();
        },
        complete: () => {
          loadingToastr.unsubscribe();
        },
      });
    }, 100);
  }

  close(): void {
    this.context.completeWith();
  }

}
