import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChannelMessageSpecial } from '@pixacare/pxc-ts-core';
import { MessageSpecialShareComponent } from '../message-special-share/message-special-share.component';
import { MessageSpecialTelemonitoringComponent }
  from '../message-special-telemonitoring/message-special-telemonitoring.component';
import { MessageSpecialAlertIndicatorsComponent }
  from '../message-special-alert-indicators/message-special-alert-indicators.component';

@Component({
  selector: 'pxc-message-special',
  standalone: true,
  imports: [
    CommonModule,
    MessageSpecialShareComponent,
    MessageSpecialTelemonitoringComponent,
    MessageSpecialAlertIndicatorsComponent,
  ],
  templateUrl: './message-special.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageSpecialComponent {

  @Input() special: ChannelMessageSpecial;
  @Input() userId: number;

}
