
<div class="flex flex-col items-center gap-2 my-4">

    <h6 class="text-center mb-2">{{title}}</h6>

    <p *ngIf="!sequences.length; else selectedSequencesPreview" class="max-w-sm text-slate-500 text-center mb-2">{{emptyState}}</p>

    <ng-template #selectedSequencesPreview>
    <pxc-sequence-stacks-preview
        [sequences]="sequences"
        (mediaClicked)="mediaClicked.emit($event)"
        class="block w-full"
        [centered]="true"
    ></pxc-sequence-stacks-preview>

    <p class="text-center text-slate-600 text-sm">
        {{ sequences.length | i18nPlural : sequenceCountPluralMapping }}
    </p>
    </ng-template>

    <button 
        tuiButton 
        size="m" 
        appearance="secondary" 
        (click)="openSequencePicker()" 
        [icon]="sequences.length ? 'tuiIconEdit2' : 'tuiIconPlus'" 
        data-testid="open-picker"
    >
        {{ sequences.length ? 'Modifier la sélection' : 'Ajouter des séquences' }} 
    </button>
</div>