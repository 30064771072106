import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FormComponent } from '../forms/form/form.component';
import { DialogService } from 'src/app/services/dialog.service';
import { FormEngineService } from '../forms/form-engine.service';
import {
  BaseSadmEntity,
  Form,
  FormAnswers,
  QuestionType,
  SadmClient,
  SadmType,
  SadmTypeLabel,
} from '@pixacare/pxc-ts-core';

@Injectable()
export class SadmEntityCreationService {

  static readonly nameTargetQuestions: { [type in SadmType]: string[] } = {
    [SadmType.SCARSPY]: ['localization'],
    [SadmType.WOUNDCARE]: ['chronic-wound-type', 'wound-localization'],
  };

  constructor(
    private readonly dialogService: DialogService,
  ) { }

  static generateEntityName(form: Form, answers: FormAnswers, entityType: SadmType): string {

    const targetQuestions = SadmEntityCreationService.nameTargetQuestions[entityType];

    if (!targetQuestions) {
      return '';
    }

    const labels = [];
    targetQuestions.forEach((questionKey) => {
      const question = form.questions[questionKey];
      if (question.type === QuestionType.RADIO) {
        const woundTypeSelectedKey = FormEngineService.getChoiceKey(answers[questionKey]);
        const woundTypeLabel = question.choices
          .find((choice) => choice.key === woundTypeSelectedKey).value;
        labels.push(woundTypeLabel);
      } else {
        labels.push(answers[questionKey]);
      }
    });

    return labels.join(', ');

  }

  openEntityCreation(sadmClient: SadmClient): Observable<BaseSadmEntity> {
    return this.dialogService.openComponentWithCloseConfirmation
    <FormComponent, FormAnswers>(FormComponent, {
      label: `Créer une ${SadmTypeLabel[sadmClient.sadmType]}`,
      data: {
        form: sadmClient.entityCreationForm,
      },
      dismissible: false,
      size: 'm',
    }).pipe(
      map((answers) => !!answers ? {
        id: null,
        name: SadmEntityCreationService.generateEntityName(
          sadmClient.entityCreationForm, answers, sadmClient.sadmType,
        ),
        sadmClientId: sadmClient.id,
        patientId: null,
        patientEntityIndex: null,
        telemonitoringid: null,
        entityCreationFormAnswerId: null,
        entityCreationFormAnswers: answers,
        clientCode: null,
        createdBy: null,
        createdOn: null,
      } : null),
    );
  }

}
