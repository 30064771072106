<tui-elastic-container class="sm:min-h-[50vh] lg:min-h-[70vh]">

    <div>
        <tui-progress-segmented 
            class="mt-2 mb-4"
            [max]="computedSections.length"
            [value]="maxSectionIndex + 1"
            [colors]="computedSectionsColors"
            size="s"
            data-testid="progress-bar"
        ></tui-progress-segmented>
        <p class="text-slate-600 text-sm">Questionnaire {{activeSectionIndex + 1 }} sur {{computedSections.length}}</p>
    </div>

    <form
        [formGroup]="formGroup"
        *tuiLet="computedSections[activeSectionIndex] as activeSection"
        class="flex flex-col gap-4 py-2"
        data-testid="form"
    >
        <h3 class="font-normal mb-2">
            {{activeSection.title}}
        </h3>

        <div 
            *ngFor="let question of activeSection.questions" 
            class="flex flex-col gap-2"
            [attr.data-testid]="question.id"
        >
            <p class="font-medium">
                {{question.title ?? question.label}}
                <span
                    *ngIf="question.parameters.required"
                    class="text-red-500"
                >*</span>
            </p>

            <ng-container [ngSwitch]="question.type">

                <ng-container *ngSwitchCase="QuestionType.TEXT">
                    <tui-textarea
                        [expandable]="true"
                        maxlength="65535"
                        [formControlName]="question.id"
                        [readOnly]="readOnly"
                        [tuiTextfieldLabelOutside]="true"
                        data-testid="text-input"
                        ngDefaultControl
                    >
                        {{question.parameters.placeholder}}
                    </tui-textarea>
                </ng-container>

                <ng-container *ngSwitchCase="QuestionType.RICH_TEXT">
                    <tui-textarea
                        [expandable]="true"
                        maxlength="65535"
                        [formControlName]="question.id"
                        [readOnly]="readOnly"
                        [tuiTextfieldLabelOutside]="true"
                        data-testid="rich-text-input"
                        ngDefaultControl
                    >
                        {{question.parameters.placeholder}}
                    </tui-textarea>
                </ng-container>

                <ng-container *ngSwitchCase="QuestionType.NUMBER">

                    <ng-container>
                        <tui-input-number 
                            [formControlName]="question.id"
                            [readOnly]="readOnly"
                            [tuiTextfieldLabelOutside]="true"
                            [step]="question.parameters.step"
                            [tuiTextfieldPostfix]="question.parameters.unit || ''"
                            data-testid="number-input"
                            ngDefaultControl
                        >
                            <ng-container *ngTemplateOutlet="numberPlaceholder"></ng-container>
                        </tui-input-number>
                    </ng-container>
                    
                    <ng-template #numberPlaceholder>
                        {{
                            question.parameters.placeholder 
                            ?? question.title + ' en ' + question.parameters.unit
                        }}
                    </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="QuestionType.BOOL">
                    <tui-toggle 
                        [formControlName]="question.id"
                        [readOnly]="readOnly"
                        size="l"
                        data-testid="toggle-input"
                        ngDefaultControl
                    ></tui-toggle>
                </ng-container>

                <div *ngSwitchCase="QuestionType.CHECKBOX" class="flex gap-2 flex-wrap" [formGroupName]="question.id">
                    <tui-checkbox-block 
                        *ngFor="let choice of question.choices" 
                        [formControlName]="choice.key"
                        [readOnly]="readOnly"
                        [attr.data-testid]="choice.key"
                        ngDefaultControl
                    >
                        {{choice.value}}
                    </tui-checkbox-block>
                </div>

                <div *ngSwitchCase="QuestionType.RADIO" class="flex gap-2 flex-wrap">
                    <tui-radio-block 
                        *ngFor="let choice of question.choices" 
                        [formControlName]="question.id"
                        [item]="choice.key"
                        [readOnly]="readOnly"
                        [attr.data-testid]="choice.key"
                        ngDefaultControl
                    >
                        {{choice.value}}
                    </tui-radio-block>
                </div>

                <ng-container *ngSwitchCase="QuestionType.SLIDER_LABEL">
                    <!-- TODO -->
                </ng-container>

                <ng-container *ngSwitchCase="QuestionType.DATE">
                    <!-- TODO -->
                </ng-container>

                <ng-container *ngSwitchCase="QuestionType.TIME">
                    <!-- TODO -->
                </ng-container>

            </ng-container>

            <tui-error
                [formControlName]="question.id"
                [error]="[] | tuiFieldError | async"
                data-testid="error-display"
            ></tui-error>

        </div>
    </form>
</tui-elastic-container>

<div class="flex max-sm:flex-col-reverse max-sm:items-stretch gap-2 mt-4">
    <button 
        tuiButton
        appearance="outline" 
        (click)="previous()"
        class="sm:mr-auto"
        data-testid="previous-button"
    >
        {{ activeSectionIndex === 0 ? 'Annuler' : 'Précédent' }}
    </button>
    <button
        tuiButton
        [appearance]="activeSectionIndex !== computedSections.length - 1 ? 'secondary' : 'primary'"
        (click)="finish()"
        [disabled]="!formGroup?.valid"
        [tuiHint]="!formGroup?.valid && hint"
        tuiHintShowDelay="200"
        tuiHintDirection="top"
        tuiHintAppearance="error"
        data-testid="finish-button"
    >Terminer</button>

    <button
        *ngIf="activeSectionIndex !== computedSections.length - 1"
        tuiButton 
        (click)="next()"
        [disabled]="computedSectionsValidity[activeSectionIndex] === SectionValidity.INVALID"
        [tuiHint]="computedSectionsValidity[activeSectionIndex] === SectionValidity.INVALID && hint"
        tuiHintShowDelay="200"
        tuiHintDirection="top"
        tuiHintAppearance="error"
        data-testid="next-button"
    >Suivant</button>

    <ng-template #hint>Au moins une des valeurs saisies est incorrecte.</ng-template>

</div>