import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientModule } from '../patient/patient.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TelemonitoringListComponent } from './telemonitoring-list/telemonitoring-list.component';
import { SequenceModule } from '../sequence/sequence.module';
import { TelemonitoringSendTutorialComponent } from
  './telemonitoring-send-tutorial/telemonitoring-send-tutorial.component';
import { TelemonitoringPreviewComponent } from './telemonitoring-preview/telemonitoring-preview.component';
import {
  TuiButtonModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import {
  TuiCheckboxLabeledModule,
  TuiMarkerIconModule,
  TuiBadgeModule,
  TuiIslandModule,
} from '@taiga-ui/kit';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TagInputComponent } from 'src/app/shared/components/tag-input/tag-input.component';
import { StatusComponent } from '../shared/status/status.component';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { SequenceMediaComponent } from '../sequence/sequence-media/sequence-media.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepartmentPickerComponent } from '../department/department-picker/department-picker.component';
import { UserPreviewComponent } from '../user/user-preview/user-preview.component';
import { CodeInputModule } from 'angular-code-input';
import { StopPropagationDirective } from '../shared/directives/stop-propagation.directive';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PatientModule,
    RouterModule,
    CodeInputModule,
    RouterModule,
    FormsModule,
    SequenceModule,
    ReactiveFormsModule,
    TuiButtonModule,
    TuiCheckboxLabeledModule,
    TuiMarkerIconModule,
    TuiHostedDropdownModule,
    TuiIslandModule,
    TuiHintModule,
    TuiSvgModule,
    TuiBadgeModule,
    TuiLetModule,
    DepartmentPickerComponent,
    SequenceMediaComponent,
    StatusComponent,
    SpinnerComponent,
    TagInputComponent,
    UserPreviewComponent,
    StopPropagationDirective,
    TuiLinkModule,
  ],
  declarations: [
    TelemonitoringListComponent,
    TelemonitoringSendTutorialComponent,
    TelemonitoringPreviewComponent,
  ],
  exports: [
    TelemonitoringListComponent,
    TelemonitoringPreviewComponent,
  ],
})
export class TelemonitoringModule { }
