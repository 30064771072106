<ng-container *ngIf="isDataLoaded$ | async; else loadingBlock">

  <ng-container *ngIf="telemonitorings$ | async as telemonitorings">

    <pxc-telemonitoring-list 
      *ngIf="telemonitorings.data; else loadingBlock"
      [telemonitorings]="telemonitorings.data" 
      data-testid="telemonitoring-list" 
      [displayPatient]="false"
      [actions]="telemonitoringActions"
      (loadNext)="loadNext()"
      (loadPrevious)="loadPrevious()"
      [isNextPageLoading]="telemonitorings.context.isNextPageLoading"
      [isPreviousPageLoading]="telemonitorings.context.isPreviousPageLoading"  
    >
      <button 
        tuiButton 
        *tuiLet="patient$ | async as patient" 
        emptyState
        icon="tuiIconPlus" 
        [disabled]="patient.isArchived"
        (click)="createTelemonitoring()">
        Démarrer un télésuivi
      </button>
  </pxc-telemonitoring-list>
  
  </ng-container>

</ng-container>

<ng-template #loadingBlock>
  <div class="my-4">
    <pxc-loader data-testid="loading">
      Chargement des télésuivis ...
    </pxc-loader>
  </div>
</ng-template>