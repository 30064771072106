<div class="flex flex-col gap-6" [ngClass]="{'!-mx-8': isDialog}">
  <ng-container *ngFor="let section of sections; trackBy: trackBySectionId">
    <div *ngIf="section.questions.length > 0" class="flex flex-col gap-6" [attr.data-testid]="'section-' + section.id">
      <div>
      <p class="font-medium px-4 pb-1">{{ section.title | titlecase }}</p>
        <ng-container *ngFor="let question of section.questions; trackBy: trackByQuestionTitle">
          <pxc-form-display-question
            *ngIf="question.value"
            [label]="question.title"
            value="{{ question.value | normalize }} {{ question.unit }}"
            [indicatorLevel]="alerts[question.id]?.[0]?.level"
            [showAlerts]="showAlerts"
          ></pxc-form-display-question>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
