<div   
  class="py-1.5 px-4 mb-0.5 transition hover:rounded"
  [ngClass]="{
    'hover:bg-slate-100/50': indicatorLevel !== IndicatorLevel.MODERATE && indicatorLevel !== IndicatorLevel.SEVERE,
    'bg-orange-50 hover:bg-orange-100/60': indicatorLevel === IndicatorLevel.MODERATE,
    'bg-red-50 hover:bg-red-100/60': indicatorLevel === IndicatorLevel.SEVERE
  }"
>
  <pxc-tile>
    <pxc-alert-indicator
      *ngIf="showAlerts"
      leading
      [level]="indicatorLevel"
    ></pxc-alert-indicator>
    <p subtitle>
      {{label}}
    </p>
    <p *ngIf="!shouldWrap" trailing class="min-w-24 mr-2 text-right">
      <ng-container *ngTemplateOutlet="answer"></ng-container>
    </p>
  </pxc-tile>
  <div *ngIf="shouldWrap" class="mt-0.5" [ngClass]="{'ml-8': showAlerts}">
    <ng-container *ngTemplateOutlet="answer"></ng-container>
  </div>  
</div>

<ng-template #answer>
  <span
    class="whitespace-pre-line"
    [ngClass]="{
      'text-orange-700': indicatorLevel === IndicatorLevel.MODERATE,
      'text-red-700': indicatorLevel === IndicatorLevel.SEVERE
    }"
  >
    {{value}}
  </span>
</ng-template>