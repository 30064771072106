<div class="flex flex-col gap-3">

  <pxc-message-bubble
    [message]="message"
    [showHeader]="true"
    data-testid="message-bubble"
  ></pxc-message-bubble>
  
  <p>Lu par</p>
  <pxc-channel-participants
    *ngIf="readers.length > 0; else emptyState"
    [participants]="readers"
    data-testid="readers"
  ></pxc-channel-participants>

  <ng-template #emptyState>
    <pxc-status
        [state]="Status.BASIC"
        icon="tuiIconEye"
        [showTitle]="false"
        description="Personne n'a lu le message !"
        data-testid="empty-state"
    >
    </pxc-status>
  </ng-template>
</div>
