<div class="flex flex-col gap-4" *ngIf="!isLoading; else loadingTemplate">

  <tui-stepper 
    [(activeItemIndex)]="activePageIndex" 
    class="justify-between mb-2 pointer-events-none"
    data-testid="stepper"
  >
    <button tuiStep *ngFor="let step of telemonitoringRequestStepsConfig">
      {{step.name}}
    </button>
  </tui-stepper>

  <tui-elastic-container 
    class="[&>*]:block [&>*]:pb-4 min-h-80" 
    [ngSwitch]="activePageIndex" 
    [formGroup]="form"
  >

    <pxc-telemonitoring-request-type 
      (creatorTypeChange)="pickCreatorType($event)" 
      *ngSwitchCase="TelemonitoringRequestStep.TYPE"
      data-testid="request-type"
    ></pxc-telemonitoring-request-type>

    <pxc-telemonitoring-request-details
      [form]="form"
      [defaultName]="defaultName"
      *ngSwitchCase="TelemonitoringRequestStep.DETAILS"
      data-testid="request-details"
    ></pxc-telemonitoring-request-details>

    <pxc-telemonitoring-collaborators
      *ngSwitchCase="TelemonitoringRequestStep.COLLABORATORS"
      formControlName="collaborators"
      [departmentIds]="form.get('departmentIds').value"
    ></pxc-telemonitoring-collaborators>

    <pxc-telemonitoring-request-location
      [form]="form" 
      *ngSwitchCase="TelemonitoringRequestStep.LOCATION"
      data-testid="request-location"
    ></pxc-telemonitoring-request-location>

    <pxc-telemonitoring-request-sequences
      [form]="form" 
      *ngSwitchCase="TelemonitoringRequestStep.SEQUENCES"
      data-testid="request-sequences"
    ></pxc-telemonitoring-request-sequences>
  
  </tui-elastic-container>

  <div class="flex items-center gap-2 md:gap-4 flex-wrap">
    <button 
      tuiButton 
      appearance="outline" 
      (click)="previousPage()"
      *ngIf="!telemonitoringRequestStepsConfig[activePageIndex].hidePrevious"
      class="mr-auto max-md:order-last max-md:w-full"
      data-testid="previous-button"
    >
      {{ activePageIndex < 1 ? 'Annuler' : 'Précédent' }}
    </button>

    <button 
      tuiButton 
      data-testid="next-button"
      (click)="nextPage()" 
      [disabled]="!isStepValid(telemonitoringRequestStepsConfig[activePageIndex].fields)"
      *ngIf="!telemonitoringRequestStepsConfig[activePageIndex].hideNext"
      class="max-md:w-full"
    >
      {{ activePageIndex === telemonitoringRequestStepsConfig.length - 1 ? 'Créer' : 'Suivant' }}
    </button>
  </div>

</div>

<ng-template #loadingTemplate>
  <pxc-telemonitoring-request-result
    [state]="state$ | async"
    (closeDialog)="closeDialog()"
  ></pxc-telemonitoring-request-result>
</ng-template>
