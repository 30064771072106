import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { ChannelMessageSpecial, FlattenedSequence, ObjectType } from '@pixacare/pxc-ts-core';
import { Observable, catchError, first, ignoreElements, of, shareReplay } from 'rxjs';
import { SequencePreviewComponent } from '../../sequence/sequence-preview/sequence-preview.component';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { SharedSequenceGalleryService } from '../../sequence/services/shared-sequence-gallery.service';
import { ResolvedShare } from 'src/app/shared/models/resolved-share';
import { MessageSpecialService } from '../channel-pane/message-special.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiLetModule } from '@taiga-ui/cdk';

@UntilDestroy()
@Component({
  selector: 'pxc-message-special-share',
  standalone: true,
  imports: [
    CommonModule,
    SequencePreviewComponent,
    SpinnerComponent,
    TuiLetModule,
  ],
  templateUrl: './message-special-share.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageSpecialShareComponent implements OnInit {

  @Input() special: ChannelMessageSpecial;
  @Input() userId: number;
  ObjectType = ObjectType;

  resolvedShare$: Observable<ResolvedShare>;
  resolvedShareError$: Observable<ResolvedShare>;

  private readonly messageSpecialService = inject(MessageSpecialService);
  private readonly sharedSequenceGalleryService = inject(SharedSequenceGalleryService);

  ngOnInit(): void {

    if (this.special?.shares) {
      const shareId = this.special.shares[this.userId] ?? this.special.shares[Object.keys(this.special.shares)[0]];
      this.resolvedShare$ = this.messageSpecialService.getSequence(shareId).pipe(
        first(),
        shareReplay(1),
      );
      this.resolvedShareError$ =  this.resolvedShare$.pipe(
        ignoreElements(),
        catchError((err) => of(err)),
        untilDestroyed(this),
      );
    }
  }

  mediaClicked(clickedMedia: MediaClickedOutput, sequence: FlattenedSequence): void {
    this.sharedSequenceGalleryService.openGallery(sequence, clickedMedia.mediaIdx);
  }

}
