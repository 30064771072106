import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SadmClient, SadmEntity, BaseSadmEntity, SadmProtocolCreation, SadmProtocol } from '@pixacare/pxc-ts-core';
import { SadmDashboard } from '@shared/models/sadms/sadm-dashboard';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class SadmHttpService {

  constructor(
    private readonly http: HttpClient,
  ) {}

  getSadmClients(params: { forms?: boolean } = { forms: false }): Observable<SadmClient[]> {
    return this.http.get<SadmClient[]>(`${environment.apiBaseUrl}/sadmclient`, {
      params: {
        forms: String(params.forms),
      },
    });
  }

  getSadmEntities({
    patientId,
    clientCode,
    flattened = false,
    includeSadmClients = false,
    fillSadmPreview = true,
    isArchived = null,
  }: {
    patientId?: number;
    clientCode?: string;
    flattened?: boolean;
    includeSadmClients?: boolean;
    isArchived?: boolean;
    fillSadmPreview?: boolean;
  } = {
    flattened: false,
    includeSadmClients: false,
    fillSadmPreview: true,
    isArchived: false,
  },
  ): Observable<SadmEntity[]> {
    return this.http.get<SadmEntity[]>(`${environment.apiBaseUrl}/sadmentity`, {
      params: {
        ...(clientCode && { clientCode: String(clientCode) }),
        ...(patientId && { patientId: String(patientId) }),
        flattened: String(flattened),
        includeSadmClients: String(includeSadmClients),
        fillSadmPreview: String(fillSadmPreview),
        ...(isArchived !== null  && { isArchived: String(isArchived) }),
      },
    });
  }

  getSadmEntity(sadmEntityId: number, {
    flattened = false,
    fillFullSizeUri = false,
    fillThumbnailUri = false,
    includeProtocolsHistory = false,
    includeSadmClient = false,
  }: {
    flattened?: boolean;
    fillFullSizeUri?: boolean;
    fillThumbnailUri?: boolean;
    includeProtocolsHistory?: boolean;
    includeSadmClient?: boolean;
  } = {}): Observable<SadmEntity> {
    return this.http.get<SadmEntity>(`${environment.apiBaseUrl}/sadmentity/${sadmEntityId}`, {
      params: {
        flattened: String(flattened),
        fillFullSizeUri: String(fillFullSizeUri),
        fillThumbnailUri: String(fillThumbnailUri),
        includeProtocolsHistory: String(includeProtocolsHistory),
        includeSadmClient: String(includeSadmClient),
      },
    });
  }

  mergeSadmEntity(fromSadmEntityId: number, toSadmEntityId: number): Observable<HttpResponse<void>> {
    return this.http.delete<HttpResponse<void>>(
      `${environment.apiBaseUrl}/sadmentity/${fromSadmEntityId}`,
      {
        params: {
          toSadmEntityId: toSadmEntityId.toString(),
        },
      },
    );
  }

  deleteSadmEntity(sadmEntityId: number): Observable<HttpResponse<void>> {
    return this.http.delete<HttpResponse<void>>(`${environment.apiBaseUrl}/sadmentity/${sadmEntityId}`);
  }

  getSadmDashboard(sadmEntityId: number): Observable<SadmDashboard> {
    return this.http.get<SadmDashboard>(`${environment.apiBaseUrl}/sadmentity/${sadmEntityId}/dashboard`);
  }

  createSadmEntity({
    entityCreationFormAnswers: sadmEntityCreationFormAnswer,
    name: sadmEntityName,
    sadmClientId,
    clientCode,
    patientId,
  }: BaseSadmEntity & {
    clientCode: string;
    patientId: number;
  }): Observable<SadmEntity> {
    return this.http.post<SadmEntity>(`${environment.apiBaseUrl}/sadmentity`, {
      name: sadmEntityName,
      sadmClientId,
      clientCode,
      patientId,
      entityCreationFormAnswer: sadmEntityCreationFormAnswer,
    });
  }

  createProtocol({ sadmEntityId, sadmClientId, answers }: SadmProtocolCreation): Observable<SadmProtocol> {
    return this.http.post<SadmProtocol>(`${environment.apiBaseUrl}/sadmentity/${sadmEntityId}/protocol`, {
      sadmClientId,
      answers: JSON.stringify(answers),
    });
  }

  udpateSadmEntity(sadmEntity: SadmEntity): Observable<SadmEntity> {
    return this.http.patch<SadmEntity>(
      `${environment.apiBaseUrl}/sadmentity/${sadmEntity.id}`,
      {
        name: sadmEntity.name,
      },
    );
  }

  archive(sadmEntityId: number): Observable<SadmEntity> {
    return this.http.patch<SadmEntity>(`${environment.apiBaseUrl}/sadmentity/${sadmEntityId}/archive`,
      {},
      {},
    );
  }

  unarchive(sadmEntityId: number): Observable<SadmEntity> {
    return this.http.patch<SadmEntity>(`${environment.apiBaseUrl}/sadmentity/${sadmEntityId}/unarchive`,
      {},
      {},
    );
  }

}
