<pxc-sequence-picker-preview
    title="Sélectionner les séquences à ajouter au télésuivi"
    emptyState="Cette étape est facultative, vous pourrez ajouter des séquences à ce télésuivi plus tard."
    [sequences]="sequences"
    (sequencesChange)="setSequence($event)"
    (mediaClicked)="mediaClicked($event)"
    [patientId]="patientId"
    [clientCode]="clientCode"
    [departmentIds]="departmentIds"
    data-testid="sequence-picker"
></pxc-sequence-picker-preview>
<tui-error
    formControlName="sequences"
    [error]="[] | tuiFieldError | async"
></tui-error>