<p class="text-sm text-slate-700">Emplacement</p>
<div [formGroup]="form">
    <pxc-department-picker
        [clientCode]="clientCode"
        (clientCodeChange)="setClientCode($event)"
        [departmentIds]="departmentIds"
        (departmentIdsChange)="setDepartmentIds($event)"
        data-testid="department-picker"
    >
        <tui-error
            formControlName="departmentIds"
            [error]="[] | tuiFieldError | async"
        ></tui-error>
    </pxc-department-picker>
</div>