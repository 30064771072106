<div class="flex justify-between items-center p-3 border-b border-slate-100">
    <div class="flex flex-col max-md:flex-col gap-3">
        <div class="flex items-center gap-3 py-1">
            <p class="font-medium">{{title}}</p>
            <tui-badge
                status="custom"
                class="bg-pixablue-3 !text-blue-900"
                [value]="counterLabel"
            ></tui-badge>
        </div>
        <pxc-user-state-count
            [userCountByAdminState]="userCountByAdminState"
        ></pxc-user-state-count>
    </div>
    <pxc-action-buttons
        [actions]="actions"
        [actionButtons]="actionButtonsGroups"
        size="s"
    ></pxc-action-buttons>
</div>