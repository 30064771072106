<ng-container *ngIf="{
    sadm: sequence.sequenceInstance.sadmEntityId,
    tm: sequence.sequenceInstance.telemonitoringId
} as ids">

    <div 
        *ngIf="(showSadm && ids.sadm) || (showTelemonitoring && ids.tm)" 
        class="border-t border-slate-100 flex flex-col gap-2 mt-2.5 pt-2.5"
        data-testid="linked-entities-list"
    >

        <ng-container *ngIf="showTelemonitoring && ids.tm">
            <ng-container *ngIf="sequencePickerService.telemonitoringNames$ | async as names; else loading">
            
                <pxc-sequence-picker-linked-entity
                    *ngIf="names[ids.tm] as name"
                    [icon]="'telemonitoring'"
                    [name]="name"
                    [link]="'Sélectionner toutes les photos liées au télésuivi'"
                    (linkClicked)="selectTelemonitoringSequences.emit(ids.tm)"
                    data-testid="telemonitoring-linked-entity"
                ></pxc-sequence-picker-linked-entity>

            </ng-container>
        </ng-container>

        <ng-container *ngIf="showSadm && ids.sadm">

            <ng-container *ngIf="sequencePickerService.sadmNames$ | async as names; else loading">
                
                <pxc-sequence-picker-linked-entity
                    *ngIf="names[ids.sadm] as name"
                    [icon]="'analysis'"
                    [name]="name"
                    [link]="'Sélectionner toutes les photos liées à la plaie'"
                    (linkClicked)="selectSadmSequences.emit(ids.sadm)"
                    data-testid="sadm-linked-entity"
                ></pxc-sequence-picker-linked-entity>

            </ng-container>
        </ng-container>

    </div> 

</ng-container>

<ng-template #loading>
    <div class="flex gap-3" data-testid="loading">
        <div class="w-6 h-6 tui-skeleton"></div>
        <div>
            <div class="w-24 h-4 tui-skeleton"></div>
            <div class="w-16 h-4 mt-2 tui-skeleton"></div>
        </div>
    </div>
</ng-template>