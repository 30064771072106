/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Channel, ChannelType } from '@pixacare/pxc-ts-core';
import { TuiSvgModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import {
  TuiDataListWrapperModule,
  TuiMultiSelectModule,
} from '@taiga-ui/kit';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ChatService } from '../../chat.service';
import { BehaviorSubject, debounceTime, filter } from 'rxjs';
import { TileComponent } from 'src/app/shared/components/tile/tile.component';
import {
  defaultChannelMessagePriorityConfig,
  ChannelMessagePriorityConfig,
  telemonitoringChannelMessagePriorityConfig,
} from '@shared/models/channel-message-priority.config';
import { AutoCompleteModel } from '@shared/models/helpers/auto-complete-model';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pxc-channel-notifications',
  standalone: true,
  imports: [
    CommonModule,
    TileComponent,
    TuiSvgModule,
    FormsModule,
    ReactiveFormsModule,
    TuiMultiSelectModule,
    TuiTextfieldControllerModule,
    TuiDataListWrapperModule,
  ],
  templateUrl: './channel-notifications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelNotificationsComponent implements OnChanges, OnInit {

  @Input() channel: Channel;

  readonly formControl = new FormControl<AutoCompleteModel[]>([]);

  currentUserId = inject(AuthenticationService).currentUser.id;
  loading$ = new BehaviorSubject<boolean>(false);

  private readonly chatService = inject(ChatService);

  get config(): ChannelMessagePriorityConfig {
    switch (this.channel?.type) {
      case ChannelType.TELEMONITORING:
        return telemonitoringChannelMessagePriorityConfig;
      default:
        return defaultChannelMessagePriorityConfig;
    }
  }

  items: AutoCompleteModel[];

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(1000),
      filter(() => this.formControl.dirty),
    ).subscribe(() => this.submit());
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (!changes.channel) {
      return;
    }

    this.items = Object.keys(this.config).map((key) => ({
      value: key,
      display: this.config[key].name,
    }));

    const enabledPriorities = [];

    this.items.forEach((item)=> {
      const priority = +item.value;

      if (this.channel.settings?.notifications?.[priority]?.includes(this.currentUserId)) {
        enabledPriorities.push(item);
      }
    });

    this.formControl.reset(enabledPriorities);

  }

  readonly stringify: TuiStringHandler<AutoCompleteModel | TuiContextWithImplicit<AutoCompleteModel>> = (item) =>
    'display' in item ? item.display : item.$implicit.display;

  readonly valueContent: TuiStringHandler<TuiContextWithImplicit<AutoCompleteModel[]>> = ({ $implicit }) => {

    const limit = 1;

    if ($implicit.length === this.items.length) {
      return 'Toutes';
    }

    if ($implicit.length <= limit) {
      return $implicit.map((item) => item.display).join(', ');
    }

    return `${$implicit.slice(0, limit).map((item) => item.display).join(', ')} +${$implicit.length - limit}`;

  };

  submit(): void {

    const choices = this.formControl.value?.map(({ value }) => parseInt(value, 10)) || [];

    this.loading$.next(true);
    this.chatService.updateNotifications(this.channel.id, choices).then(
      () => this.loading$.next(false),
    );

  }

}
