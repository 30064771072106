<div class="flex gap-2 items-center py-1 max-sm:flex-col-reverse">

  <ng-content></ng-content>

  <button 
    tuiButton 
    appearance="flat" 
    size="s"
    (click)="toggleAll()"
    data-testid="checkbox-all"
    [disabled]="disabledItems.length === items?.length"
    class="ml-auto"
  >
    {{ items?.length === selection.length 
      ? 'Tout désélectionner' 
      : 'Tout sélectionner'}}
  </button>

</div>

<tui-scrollbar 
  class="flex-1 w-full border border-slate-200 rounded-xl bg-white" 
  scroll-tracker 
  [threshold]="{bottom: 100}" 
  (bottomThreshold)="loadNextPage.emit()" 
  data-testid="scroll-wrapper"
>

  <tui-data-list 
    [emptyContent]="emptyContent" 
    size="s" 
  >

    <tui-opt-group 
      tuiMultiSelectGroup 
      [ngModel]="selection" 
      (ngModelChange)="selectionChange.emit($event)" 
      data-testid="opt-group"
      ngDefaultControl
      >

      <tui-opt-group>

        <button 
          *ngFor="let item of items; trackBy: trackByValue" 
          tuiOption
          size="m" 
          [value]="item.value"
          [disabled]="disabledItems.includes(item.value)"
        >
          <p>{{item.display}}</p>
        </button>

      </tui-opt-group>

    </tui-opt-group>

  </tui-data-list>
</tui-scrollbar>

<ng-template #emptyContent>
  <p class="text-center my-4 text-slate-500 mx-auto max-w-prose">{{emptyMessage}}</p>
</ng-template>
