<pxc-message-special-share
    *ngIf="special?.shares"
    [special]="special"
    [userId]="userId"
></pxc-message-special-share>

<pxc-message-special-alert-indicators
    *ngIf="special?.alertIndicators"
    [alertReportQuestions]="special.alertIndicators"
></pxc-message-special-alert-indicators>

<pxc-message-special-telemonitoring
    *ngIf="special?.telemonitoringId"
    [telemonitoringId]="special.telemonitoringId"
    class="flex justify-end"
></pxc-message-special-telemonitoring>