import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CreatorType } from '@pixacare/pxc-ts-core';
import { phoneNumberValidator } from '@shared/validators/validators';

export const validateDepartmentIds = (form: FormGroup): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {

    const clientCode = form.get('clientCode').value;

    // we can select 0 departments for common client
    if (clientCode === 'common') {
      return null;
    }

    // we must select at least 1 department for other clients
    const departmentIds = control.value as number[];
    if (!departmentIds || departmentIds.length === 0) {
      return { departmentRequired: true };
    }
    return null;

  };

export const validatePatientContact = (form: FormGroup, phoneNumberRegex: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {

    // if the creator is the user, we don't need to validate the patient contact
    if (form.get('creatorType').value === CreatorType.BY_USER) {
      return null;
    }

    // if the creator is the patient, we must select a patient contact
    const phoneNumber = control.value?.phoneNumber as string | null;
    if (!phoneNumber) {
      return { required: true };
    }

    const validator = phoneNumberValidator(phoneNumberRegex);
    return validator(new FormControl(phoneNumber));
  };
