<tui-combo-box
    [ngModel]="value$ | async"
    (ngModelChange)="onValueChange($event)"
    ngDefaultControl
    [tuiTextfieldLabelOutside]="true"
    [disabled]="disabled"
    [stringify]="stringify"
    [tuiTextfieldCleaner]="true"
    data-testid="combo-box"
>
    {{placeholder}}
    <input
        tuiTextfield
        type="tel" 
        data-testid="phone-input"
        (input)="onInput(extractValueFromEvent($event))"
    />
    <tui-data-list-wrapper
        *tuiDataList
        [items]="items$ | async | tuiFilterByInputWith : stringify"
        [itemContent]="stringify | tuiStringifyContent"
        emptyContent="Entrez un numéro de téléphone"
    ></tui-data-list-wrapper>
</tui-combo-box>