import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class IsValidUserGuard  {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (
      !!this.authenticationService.currentUser
      && this.authenticationService.currentUser.isValid
    ) {
      return true;
    }
    this.router.navigate(['/user/validate'], {
      queryParams: { intentUrl: state.url },
    });
    return false;
  }

}
