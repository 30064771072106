import { Factory } from '../Factory.js';
import { Node } from '../Node.js';
import { getNumberValidator } from '../Validators.js';
Factory.addGetterSetter(Node, 'hue', 0, getNumberValidator(), Factory.afterSetFilter);
Factory.addGetterSetter(Node, 'saturation', 0, getNumberValidator(), Factory.afterSetFilter);
Factory.addGetterSetter(Node, 'luminance', 0, getNumberValidator(), Factory.afterSetFilter);
export const HSL = function (imageData) {
  var data = imageData.data,
    nPixels = data.length,
    v = 1,
    s = Math.pow(2, this.saturation()),
    h = Math.abs(this.hue() + 360) % 360,
    l = this.luminance() * 127,
    i;
  var vsu = v * s * Math.cos(h * Math.PI / 180),
    vsw = v * s * Math.sin(h * Math.PI / 180);
  var rr = 0.299 * v + 0.701 * vsu + 0.167 * vsw,
    rg = 0.587 * v - 0.587 * vsu + 0.33 * vsw,
    rb = 0.114 * v - 0.114 * vsu - 0.497 * vsw;
  var gr = 0.299 * v - 0.299 * vsu - 0.328 * vsw,
    gg = 0.587 * v + 0.413 * vsu + 0.035 * vsw,
    gb = 0.114 * v - 0.114 * vsu + 0.293 * vsw;
  var br = 0.299 * v - 0.3 * vsu + 1.25 * vsw,
    bg = 0.587 * v - 0.586 * vsu - 1.05 * vsw,
    bb = 0.114 * v + 0.886 * vsu - 0.2 * vsw;
  var r, g, b, a;
  for (i = 0; i < nPixels; i += 4) {
    r = data[i + 0];
    g = data[i + 1];
    b = data[i + 2];
    a = data[i + 3];
    data[i + 0] = rr * r + rg * g + rb * b + l;
    data[i + 1] = gr * r + gg * g + gb * b + l;
    data[i + 2] = br * r + bg * g + bb * b + l;
    data[i + 3] = a;
  }
};