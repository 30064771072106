import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '@services/location.service';
import { FilterType } from '@shared/models/filters/filter-type.enum';
import { AutoCompleteModel } from '@shared/models/helpers/auto-complete-model';
import { TuiTabsModule } from '@taiga-ui/kit';
import { map, switchMap, of, combineLatest, first, filter } from 'rxjs';
import { FilterBarService } from '../filter-bar.service';
import { makeFilter } from '@shared/utils/filter-utils';

@Component({
  selector: 'pxc-filter-tabs',
  templateUrl: './filter-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiTabsModule,
  ],
})
export class FilterTabsComponent implements OnInit {

  activeItemIndex = 0;

  template$ = this.filterBarService.templates$.pipe(
    map((templates) => templates
      .find((template) => template.type === FilterType.TABS),
    ),
  );

  tabs$ = this.template$.pipe(
    switchMap((template) => template ? template.getValue('') : of(null)),
  );

  constructor(
    private readonly filterBarService: FilterBarService,
    private readonly route: ActivatedRoute,
    private readonly locationService: LocationService,
  ) { }

  ngOnInit(): void {
    combineLatest([this.route.queryParamMap, this.template$, this.tabs$]).pipe(
      first(),
      filter(([, , tabs]) => !!tabs),
    ).subscribe(([queryParams, template, tabs]) => {
      const queryParamFilter = queryParams.get(template.property);
      const targetTabIndex = tabs.findIndex((tab) => tab.value === queryParamFilter);
      this.activeItemIndex = targetTabIndex > -1 ? targetTabIndex : this.activeItemIndex;
      this.onTabChange(tabs[this.activeItemIndex]);
    });
  }

  onTabChange(tab: AutoCompleteModel): void {
    this.template$.pipe(first()).subscribe((template) => {
      this.filterBarService.updateTemplate({
        ...template,
        filters: [
          ...(tab.value ? [makeFilter(tab, template)] : []),
        ],
      });
      this.locationService.updateUrlParams(this.route, { [template.property]: tab.value });
    });
  }

  trackByTab(index: number, tab: AutoCompleteModel): string {
    return tab.value;
  }

}
