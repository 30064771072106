import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { SadmEntity, FormAnswer, FormReport } from '@pixacare/pxc-ts-core';
import { ChartService } from '@shared/charts/chart.service';
import { SadmDashboard } from '@shared/models/sadms/sadm-dashboard';
import { patientsActions } from '@shared/store/patients/patients.actions';
import { sadmActions } from '@shared/store/sadm/sadm.actions';
import { selectSadmEntity, selectSadmDashboard } from '@shared/store/sadm/sadm.selectors';
import { sequencesActions } from '@shared/store/sequences/sequences.actions';
import { Observable, combineLatest, first, map, switchMap, pipe } from 'rxjs';

@UntilDestroy()
@Injectable()
export class SadmService {

  sadmEntity$: Observable<SadmEntity> = null;
  dashboard$: Observable<SadmDashboard> = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly chartService: ChartService,
  ) {

    this.sadmEntity$ = this.getSadmEntity();
    this.dashboard$ = this.getDashboard();

    combineLatest([
      this.dashboard$,
      this.getSadmEntityId(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([dashboard, sadmEntityId]) => {
        if (!dashboard) {
          this.store.dispatch(sadmActions.getSadmDashboard({ sadmEntityId }));
        }
      });

    combineLatest([
      this.sadmEntity$,
      this.dashboard$,
    ]).pipe(
      first(([sadmEntity, dashboard]) =>
        !!dashboard && !!sadmEntity?.sequencesIds),
    ).subscribe(([sadmEntity]) => {

      if (!sadmEntity.patient) {
        this.store.dispatch(patientsActions.getPatient({
          patientId: sadmEntity.patientId,
          clientCode: sadmEntity.clientCode,
        }));
      }

      if (sadmEntity.sequences?.length !== sadmEntity.sequencesIds?.length) {
        const loadedSequencesIds = sadmEntity.sequences?.map((sequence) => sequence.sequenceInstance.id);

        const sequencesIdsToLoad = sadmEntity.sequencesIds
          .filter((sequenceId) => !loadedSequencesIds.includes(sequenceId));

        sequencesIdsToLoad.forEach((sequenceId) => {
          this.store.dispatch(sequencesActions.getUserSequence({ clientCode: sadmEntity.clientCode, sequenceId }));
        });
      }

    });

  }

  getFormAnswerByIndex(index: number): Observable<{
    sequenceId: number;
    formAnswer: FormAnswer;
  }> {

    const sequence$ = this.sadmEntity$.pipe(
      map((sadmEntity) => sadmEntity.sequences[index]),
    );

    sequence$.pipe(
      first(),
    ).subscribe((sequence) => {
      if (!sequence.sequenceInstance.formAnswer && sequence.sequenceInstance.formAnswerId) {
        this.store.dispatch(sequencesActions.getSequenceAnalysis({
          clientCode: sequence.clientCode,
          sequenceId: sequence.sequenceInstance.id,
        }));
      }
    });

    return sequence$.pipe(
      map((sequence) => ({
        sequenceId: sequence.sequenceInstance.id,
        formAnswer: sequence.sequenceInstance.formAnswer,
      })),
      first(({ formAnswer }) => !!formAnswer),
    );

  }

  getFormSummaryIndex(sequenceId: number): Observable<number> {
    return this.dashboard$.pipe(
      first((dashboard) => !!dashboard?.formReports?.length),
      map((dashboard) => {
        const index = dashboard.formReports.findIndex(
          (formReport: FormReport) => formReport.media.sequenceId === sequenceId,
        );
        return index > -1 ? index : dashboard.formReports.length - 1;
      }),
    );
  }

  private getSadmEntityId(): Observable<number> {
    return this.route.paramMap.pipe(
      map((params) => +params.get('sadmEntityId')),
      first(),
    );
  }

  private getSadmEntity(): Observable<SadmEntity> {
    return this.getSadmEntityId().pipe(
      switchMap((sadmEntityId) => this.store.select(selectSadmEntity(sadmEntityId))),
    );
  }

  private getDashboard(): Observable<SadmDashboard> {
    return this.getSadmEntityId().pipe(
      switchMap((sadmEntityId) => this.store.select(selectSadmDashboard(sadmEntityId))),
      pipe(
        map((dashboard) => {
          if (dashboard) {
            return {
              ...dashboard,
              charts: dashboard.charts?.map((chart) =>
                this.chartService.createChartConfig(chart.config),
              ),
            };
          }
          return null;
        }),
      ),
    );
  }

}
