<div class="flex gap-3">
  <tui-svg [src]="icon" data-testid="icon"></tui-svg>
  <div>
    <p class="text-sm">
      Liée à 
      <span class="font-medium">
        {{name}}
      </span>
    </p>
    <a 
      tuiLink
      data-appearance="primary"
      class="!text-sm"
      (click)="linkClicked.emit()"
      data-testid="link"
    >{{link}}</a>
  </div>
</div>