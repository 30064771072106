import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const jwt = this.authenticationService.getToken();

    if (!jwt) {
      return next.handle(req);
    }

    const cloned = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${jwt}`),
    });

    return next.handle(cloned);
  }

}
