import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiButtonModule, TuiDataListModule, TuiScrollbarModule } from '@taiga-ui/core';
import { ListItem } from 'src/app/shared/models/helpers/list-item';
import { SharedModule } from '../shared.module';
import { TuiMultiSelectModule } from '@taiga-ui/kit';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'pxc-list-picker',
  templateUrl: './list-picker.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    TuiScrollbarModule,
    SharedModule,
    TuiDataListModule,
    TuiMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPickerComponent {

  @Input() items: ListItem[];
  @Input() disabledItems: number[] = [];
  @Input() emptyMessage: string;
  @Input() selection: number[];

  @Output() selectionChange = new EventEmitter<number[]>();
  @Output() loadNextPage = new EventEmitter<void>();

  trackByValue(index: number, item: ListItem): number {
    return item.value;
  }

  toggleAll(): void {
    if (this.items.length === this.selection.length) {
      // uncheck
      // keep only checked & disabled items
      this.selectionChange.emit(this.selection.filter(
        (item) => this.selection.includes(item) && this.disabledItems.includes(item),
      ));
    } else {
      // check
      // everything that is not disabled (while keeping already selected items that could be disabled)
      this.selectionChange.emit([...new Set([
        ...this.selection,
        ...this.items.filter((item) => !this.disabledItems.includes(item.value))
          .map((item) => item.value),
      ])]);
    }

  }

}
