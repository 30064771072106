<div *ngIf="actionOnGoing$ | async; else tokenCheckedBlock">
  <pxc-loader data-testid="loading">
    Vérification en cours ...
  </pxc-loader>
</div>

<ng-template #tokenCheckedBlock>
  <div *ngIf="tokenUser as user; else invalidTokenBlock">
    <div class="flex flex-col gap-2 py-2">
      <h2>
        Bonjour {{ user.firstName }},
      </h2>
      <p class="max-w-prose">
        Afin de réinitialiser votre mot de passe, veuillez entrer votre
        nouveau mot de passe et le confirmer.
      </p>
    </div>

    <form class="flex flex-col gap-4 mt-6" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" data-testid="form">
      <div>
        <tui-input-password
          formControlName="inputPassword"
          tuiTextfieldIconLeft="tuiIconLockLarge"
          [disabled]="(actionOnGoing$ | async) ? '' : null"
        >
          Mot de passe
          <input tuiTextfield type="password" data-testid="password-input">
        </tui-input-password>
        <app-input-validation-error-display [control]="inputPassword">
        </app-input-validation-error-display>
      </div>

      <div class="form-group">
        <tui-input-password
          formControlName="inputPasswordConfirm"
          tuiTextfieldIconLeft="tuiIconLockLarge"
          [disabled]="(actionOnGoing$ | async) ? '' : null"
        >
          Confirmation du mot de passe
          <input tuiTextfield type="password" data-testid="confirm-password-input">
        </tui-input-password>
        <app-input-validation-error-display [control]="inputPasswordConfirm">
        </app-input-validation-error-display>
      </div>

      <button 
        tuiButton 
        class="w-full" 
        type="submit" 
        [disabled]="actionOnGoing$ | async" 
        appearance="primary"
        data-testid="confirm"
      >
        Changer le mot de passe
      </button>
    </form>
  </div>

  <ng-template #invalidTokenBlock>
    <p class="text-center w-full pb-4" data-testid="token-invalid">Votre lien a expiré.</p>
    <button 
      tuiButton 
      appearance="primary"
      class="w-full" 
      routerLink="../reset-password-request"
    >
      Demander un nouveau lien
    </button>
  </ng-template>
</ng-template>