import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SadmEntityPreviewComponent } from './sadm-entity-preview/sadm-entity-preview.component';
import { SadmEntityListComponent } from './sadm-entity-list/sadm-entity-list.component';
import { SadmEntityFormSummaryComponent } from './sadm-entity-form-summary/sadm-entity-form-summary.component';
import { RouterModule } from '@angular/router';
import { SadmEntityThumbnailsCarouselComponent } from
  './sadm-entity-thumbnails-carousel/sadm-entity-thumbnails-carousel.component';
import { SadmEntityMergeComponent } from './sadm-entity-merge/sadm-entity-merge.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  TuiCarouselModule,
  TuiCheckboxLabeledModule,
  TuiInputDateModule,
  TuiIslandModule,
  TuiMarkerIconModule,
  TuiRadioLabeledModule,
  TuiTabsModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import { TuiButtonModule, TuiScrollbarModule } from '@taiga-ui/core';
import { TuiSvgModule } from '@taiga-ui/core';
import { SadmEntityPickerComponent } from './sadm-entity-picker/sadm-entity-picker.component';
import { SadmFormInputComponent } from './sadm-form-input/sadm-form-input.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { SadmEntityProtocolCardComponent }
  from './sadm-entity-protocol-card/sadm-entity-protocol-card.component';
import { SadmEntityProtocolsComponent } from './sadm-entity-protocols/sadm-entity-protocols.component';
import { PatientModule } from '../patient/patient.module';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { FormDisplayComponent } from '@modules/forms/form-display/form-display.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TuiCarouselModule,
    TuiButtonModule,
    TuiIslandModule,
    TuiSvgModule,
    TuiInputDateModule,
    TuiTextareaModule,
    TuiCheckboxLabeledModule,
    TuiRadioLabeledModule,
    TuiLetModule,
    TuiMarkerIconModule,
    PatientModule,
    TuiScrollbarModule,
    TuiTabsModule,
    SpinnerComponent,
    SadmEntityPreviewComponent,
    SadmEntityPickerComponent,
    SadmEntityProtocolsComponent,
    FormDisplayComponent,
  ],
  declarations: [
    SadmEntityListComponent,
    SadmEntityFormSummaryComponent,
    SadmEntityThumbnailsCarouselComponent,
    SadmEntityMergeComponent,
    SadmFormInputComponent,
    SadmEntityProtocolCardComponent,
  ],
  exports: [
    SadmEntityListComponent,
    SadmEntityFormSummaryComponent,
    SadmEntityThumbnailsCarouselComponent,
    SadmFormInputComponent,
    SadmEntityProtocolCardComponent,
  ],
})
export class SadmModule { }
