<div class="flex flex-col gap-2 pb-4">
  <div 
    *ngIf="(department$ | async) as department"
    class="flex justify-between lg:items-center max-lg:flex-col gap-6 lg:gap-3"
  >
    <pxc-client-title-header
      *ngIf="(client$ | async) as client"
      [title]="department.name"
      subtitle="Administration du groupe"
      [clientCdnImagePath]="client.cdnImagePath"
    ></pxc-client-title-header>
    <pxc-toggle
      data-testid="collaboration-toggle"
      [toggle]="department.isOpen"
      (toggleChange)="updateDepartmentCollaboration($event)"
      hint="Lorsque la collaboration est activée, tous les utilisateurs du groupe peuvent voir les séquences des uns et des autres."
    >
      Collaboration ouverte
    </pxc-toggle>
  </div>

  <nav tuiTabs class="my-2">
    <button 
      *ngFor="let tab of tabs"
      tuiTab
      [routerLink]="tab.link"
      [routerLinkActive]="['active']"
      queryParamsHandling="merge"
      [attr.data-testid]="tab.link"
    >
      <tui-svg [src]="tab.icon" class="mr-1"></tui-svg>
      {{tab.title}}
    </button>
  </nav>

  <router-outlet></router-outlet>
</div>