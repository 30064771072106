import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output,
} from '@angular/core';
import { SequencePickerService } from '../sequence-picker.service';
import { trackBySequenceId } from 'src/app/shared/utils/trackBySequenceId';
import { CommonModule } from '@angular/common';
import { TuiNotificationModule, TuiScrollbarModule } from '@taiga-ui/core';
import {
  TuiCheckboxLabeledModule,
  TuiIslandModule,
  TuiRadioLabeledModule,
} from '@taiga-ui/kit';
import { SequencePreviewComponent } from '../../sequence-preview/sequence-preview.component';
import { SequencePickerLinkedEntitiesListComponent }
  from '../sequence-picker-linked-entities-list/sequence-picker-linked-entities-list.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StopPropagationDirective } from 'src/app/modules/shared/directives/stop-propagation.directive';
import { SpinnerComponent } from 'src/app/modules/shared/spinner/spinner.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { tap } from 'rxjs';

@Component({
  selector: 'pxc-sequence-select-list',
  templateUrl: './sequence-select-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiIslandModule,
    TuiNotificationModule,
    SequencePreviewComponent,
    SequencePickerLinkedEntitiesListComponent,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TuiRadioLabeledModule,
    TuiCheckboxLabeledModule,
    TuiScrollbarModule,
    StopPropagationDirective,
    SpinnerComponent,
    TuiLetModule,
  ],
})
export class SequenceSelectListComponent {

  @Input() selectedSequenceIds: number[] = [];
  @Input() disabledSequenceIds: number[] = [];
  @Input() singleSelectMode = false;
  @Input() hidePatient = true;
  @Input() showTelemonitorings = false;
  @Input() showSadms = false;

  @Input() badgeContent?:  (sequence: FlattenedSequence) => string | null;

  @Output() selectSequences: EventEmitter<number[]> = new EventEmitter();
  @Output() unselectSequences: EventEmitter<number[]> = new EventEmitter();
  @Output() isAllSequencesSelectedChange: EventEmitter<boolean> = new EventEmitter();

  isAllSequencesSelected = false;

  trackBySequenceId = trackBySequenceId;

  readonly sequences$ = this.sequencePickerService.displayedSequences$.pipe(
    tap((sequences) => this.checkIfAllSequenceSelected(sequences.data)),
  );

  constructor(
    private readonly sequencePickerService: SequencePickerService,
  ) {}

  loadNextSequences(): void {
    this.sequencePickerService.loadNextSequences();
  }

  selectTelemonitoringSequences(telemonitoringId: number, sequences: FlattenedSequence[]): void {
    const sequenceIds = sequences
      .filter((sequence) => sequence.sequenceInstance.telemonitoringId === telemonitoringId)
      .map((sequence) => sequence.sequenceInstance.id);
    this.selectSequences.emit(sequenceIds);
    this.checkIfAllSequenceSelected(sequences);
  }

  selectSadmSequences(sadmEntityId: number, sequences: FlattenedSequence[]): void {
    const sequenceIds = sequences
      .filter((sequence) => sequence.sequenceInstance.sadmEntityId === sadmEntityId)
      .map((sequence) => sequence.sequenceInstance.id);
    this.selectSequences.emit(sequenceIds);
    this.checkIfAllSequenceSelected(sequences);
  }

  handleCheckChange(
    sequenceId: number,
    sequences: FlattenedSequence[],
    checked: { currentTarget: HTMLInputElement },
  ): void {
    if (checked) {
      this.selectSequences.emit([sequenceId]);
      this.checkIfAllSequenceSelected(sequences);
    } else {
      this.unselectSequences.emit([sequenceId]);
      this.isAllSequencesSelected = false;
      this.isAllSequencesSelectedChange.emit(this.isAllSequencesSelected);
    }
  }

  checkIfAllSequenceSelected(sequences: FlattenedSequence[]): void {
    const allSelectableSequenceIds = this.getAllSelectableSequenceIds(sequences);
    if (this.selectedSequenceIds.length) {
      this.isAllSequencesSelected = allSelectableSequenceIds.every((id) => this.selectedSequenceIds.includes(id));
    } else {
      this.isAllSequencesSelected = false;
    }
    this.isAllSequencesSelectedChange.emit(this.isAllSequencesSelected);
  }

  handleRadioCheck(sequenceId: number): void {
    this.selectSequences.emit([sequenceId]);
  }

  checkUncheckAll(sequences: FlattenedSequence[], checked: { currentTarget: HTMLInputElement }): void {
    if (checked) {
      this.selectSequences.emit(this.getAllSelectableSequenceIds(sequences));
    } else {
      this.unselectSequences.emit(this.getAllSelectableSequenceIds(sequences));
    }
    this.isAllSequencesSelected = !!checked;
    this.isAllSequencesSelectedChange.emit(this.isAllSequencesSelected);
  }

  private getAllSelectableSequenceIds(sequences: FlattenedSequence[]): number[] {
    return sequences.reduce((acc, sequence) => {
      if (!this.disabledSequenceIds?.includes(sequence.sequenceInstance.id)) {
        return [...acc, sequence.sequenceInstance.id];
      }
      return acc;
    }, []);
  }

}
