<div class="flex flex-col p-4 lg:p-8">
  <pxc-back-navigation-button 
    class="block mb-4 w-fit"
    label="Retour à la liste" 
    data-testid="back-button"
    defaultUrl="/dashboard/"
  ></pxc-back-navigation-button>

  <ng-container *ngIf="(sadmEntity$ | async) as sadmEntity; else loading">
    <ng-container *tuiLet="(isArchived$ | async) as isArchived">
      <ng-template #header>
        <div class="flex justify-between items-center w-full animate-in fade-in slide-in-from-top-1">

          <div class="flex justify-start items-center">
            <pxc-title
              title="Bilan d'évolution {{ sadmEntity | sadmEntityName: sadmEntity.sadmClient.sadmType}}"
            ></pxc-title>
            
            <div *ngIf="isArchived">
              <pxc-archived-badge></pxc-archived-badge>
            </div>

          </div>

          <pxc-action-buttons [entity]="sadmEntity" [actions]="actions"></pxc-action-buttons>

        </div>
        
      </ng-template>

      <ng-container *ngIf="sadmEntity.sequences !== null; else loading">

        <ng-container *ngIf="(dashboard$ | async) as dashboard; else loading">
        
        <div class="flex flex-col xl:flex-row gap-3 h-[80vh] max-xl:mb-4 animate-in fade-in"
          [ngClass]="{'xl:items-start': dashboard.dashboardConfig.dashboard.display !== Display.WIDE}">

            <div class="xl:hidden w-full">
              <ng-container [ngTemplateOutlet]="header"></ng-container>
            </div>

            <div class="flex flex-col gap-4 xl:w-2/5 xl:h-full">

                <pxc-patient-card
                  *ngIf="sadmEntity?.patient"
                  [patient]="sadmEntity.patient"
                  [routerLink]="['/dashboard/patients', sadmEntity.patient.id]"
                  queryParamsHandling="merge"
                  class="cursor-pointer"
                  data-testid="patient-card"
                ></pxc-patient-card>

                <div *ngIf="sadmEntity.patient && sadmEntity.patient.isArchived">
                  <pxc-archived-notification 
                    title="Le patient de cette analyse est archivé"
                    description="Vous ne pouvez plus modifier ou ajouter de données à ce patient.">
                  </pxc-archived-notification>
                </div>
                
                <pxc-sadm-entity-protocol-card
                  *ngIf="sadmEntity.sadmClient?.protocolCreationFormId"
                  [sadmEntity]="sadmEntity"
                  [sadmClient]="sadmEntity.sadmClient"
                  (openClick)="openProtocols()"
                  (create)="createProtocol()"
                  data-testid="protocol-card"
                ></pxc-sadm-entity-protocol-card>

                <div *ngIf="dashboard.dashboardConfig.summary.display === Display.LEFT" class="flex-1">
                  <tui-island class="px-0">
                    <ng-container [ngTemplateOutlet]="formSummary"></ng-container>
                  </tui-island>  
                </div>

            </div>

            <div class="flex flex-col gap-2 flex-1">
              
              <div class="max-xl:hidden w-full">
                <ng-container [ngTemplateOutlet]="header"></ng-container>
              </div>

              <ng-template #emptyCharts>
                <div class="flex flex-col gap-8 items-center h-full flex-1 justify-center">
                  <tui-svg src="tuiIconPieChart" class="!text-4xl text-slate-400"></tui-svg>
                  <p class="text-slate-600">Aucun graphique disponible</p>
                </div>
              </ng-template>

              <ng-container *ngIf="dashboard.dashboardConfig.dashboard.display === Display.WIDE">
                <tui-island *ngIf="sadmEntity.sequences?.length > 0; else emptyCharts" class="flex-1 overflow-y-auto h-full">
                  <div
                    class="flex flex-col lg:grid grid-system xl:h-full"
                    [ngClass]="dashboard.dashboardConfig.dashboard.classes"
                    data-testid="charts"
                  >
                    <div *ngFor="let chart of dashboard.charts" class="relative overflow-hidden"
                      [ngClass]="chart.config.classes">

                      <canvas baseChart class="max-lg:min-h-[150px]"
                        [id]="chart.config.id"
                        [data]="chart.config.data"
                        [options]="chart.config.options"
                        [plugins]="chart.config.plugins"
                        [type]="chart.config.type"
                        (chartClick)="onChartClick($event)"
                        (chartHover)="onChartHover($event)"
                      ></canvas>
                    </div>
                  
                  </div>
                </tui-island>
              </ng-container>

              <tui-island class="px-0" *ngIf="dashboard.dashboardConfig.summary.display === Display.WIDE">
                <ng-container [ngTemplateOutlet]="formSummary"
                  [ngTemplateOutletContext]="{fullScreenMode: true}"></ng-container>
              </tui-island>
            </div>
          </div>

          <ng-template #emptyEntity>
            <div class="flex flex-col gap-2 items-center justify-center h-full w-full my-4" data-testid="no-sequences-message">
              <tui-svg src="tuiIconFileLarge" class="!text-4xl text-slate-400 my-4"></tui-svg>
              <p class="text-slate-600">Aucun questionnaire complété</p>
              <button 
                tuiButton 
                appearance="secondary" 
                (click)="createSequence()"
                iconRight="tuiIconPlus"
                data-testid="add-sequence-btn"
                [disabled]="isArchived"
              >
                Ajouter
              </button>
            </div>
        </ng-template>

        <ng-template #formSummary let-fullScreenMode="fullScreenMode">

          <pxc-sadm-entity-form-summary 
            *ngIf="sadmEntity.sequences?.length > 0; else emptyEntity"
            class="h-full" 
            [reports]="dashboard.formReports" 
            [sadmEntityId]="sadmEntity.id"
            [clientCode]="sadmEntity.clientCode"
            [(currentIndex)]="currentFormSummaryIndex"
            data-testid="form-summary"
          >
            <button
              tuiButton
              appearance="secondary"
              iconRight="tuiIconPlus"
              (click)="addReport()"
              size="s"
              data-testid="add-form"
              [disabled]="isArchived"
            >
              Ajouter
            </button>
            <button
              tuiButton
              appearance="secondary"
              iconRight="tuiIconEdit"
              (click)="editReport()"
              size="s"
              data-testid="edit-form"
              [showLoader]="editAnalysisButtonLoading$ | async"
              [disabled]="isArchived"
            >
              Modifier
            </button>
          </pxc-sadm-entity-form-summary>
        </ng-template>

        </ng-container>

      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <pxc-loader data-testid="loading">Chargement du tableau de bord en cours ...</pxc-loader>
  </ng-template>
</div>