import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiLinkModule, TuiSvgModule } from '@taiga-ui/core';

@Component({
  selector: 'pxc-sequence-picker-linked-entity',
  templateUrl: './sequence-picker-linked-entity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiSvgModule,
    TuiLinkModule,
  ],
})
export class SequencePickerLinkedEntityComponent {

  @Input() name: string;
  @Input() icon: string;
  @Input() link: string;

  @Output() linkClicked = new EventEmitter<void>();

}
