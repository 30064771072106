import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { selectSadmEntity } from 'src/app/shared/store/sadm/sadm.selectors';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { GalleryService } from '../gallery/gallery.service';
import { GalleryItem } from 'src/app/shared/models/gallery-item';

@Injectable({
  providedIn: 'root',
})
export class SadmGalleryService {

  constructor(
    private readonly galleryService: GalleryService,
    private readonly store: Store,
  ) { }

  openGallery(sadmEntityId: number, clientCode: string, openIdx: number): void {
    const sadmEntity$ = this.store.select(selectSadmEntity(sadmEntityId));

    const items$: Observable<GalleryItem[]> = sadmEntity$.pipe(
      map((entity): GalleryItem[] => (
        entity.sequences.map((sequence): GalleryItem => ({
          pictureSrc: sequence.medias[0].uri,
          thumbnailSrc: sequence.medias[0].thumbnailUri,
          mediaId: sequence.medias[0].id,
          parentId: sequence.medias[0].sequenceId,
        }))
      )),
    );

    this.galleryService.open({
      items$,
      openIdx,
      onImageLoadingError: (galleryIdx: number) => {
        items$.pipe(first()).subscribe((items) => {
          const item = items[galleryIdx];
          this.store.dispatch(sequencesActions.updateSequenceMedia({
            clientCode,
            sequenceId: item.parentId,
            mediaId: item.mediaId,
          }));
        });
      },
    }).subscribe();
  }

}
