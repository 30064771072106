import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSelectComponent } from '@modules/forms/form-select/form-select.component';
import { PatientContactInputComponent } from '@modules/patient/patient-contact-input/patient-contact-input.component';
import { TuiErrorModule, TuiLabelModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import {
  TuiFieldErrorPipeModule,
  TuiInputModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import { CreatorType } from '@pixacare/pxc-ts-core';
import { TelemonitoringRequestForm } from '../telemonitoring-request/telemonitoring-request-form';

@Component({
  selector: 'pxc-telemonitoring-request-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    TuiTextareaModule,
    TuiLabelModule,
    TuiFieldErrorPipeModule,
    TuiErrorModule,
    PatientContactInputComponent,
    FormSelectComponent,
  ],
  templateUrl: './telemonitoring-request-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringRequestDetailsComponent {

  @Input() defaultName: string | null = null;

  @Input() form: FormGroup<TelemonitoringRequestForm>;

  CreatorType = CreatorType;

  get creatorType(): CreatorType {
    return this.form.get('creatorType').value;
  }

  get patientId(): number | null {
    return this.form.get('patientId').value;
  }

  get clientCode(): string {
    return this.form.get('clientCode').value;
  }

}
