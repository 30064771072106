<ng-container *ngIf="sequences.length > 0; else noSequenceBlock">

  <tui-scrollbar
    scroll-tracker
    [threshold]="{top: 700, bottom: 300}"
    class="max-h-[70vh] rounded-xl"
    [topGuard]="isPreviousPageLoading"
    [bottomGuard]="isNextPageLoading"
    (topThreshold)="this.loadPrevious.emit()"
    (bottomThreshold)="this.loadNext.emit()"
    data-testid="scroll-viewport"
  >

    <div class="flex flex-col gap-2 items-stretch">

      <ng-container *ngIf="isPreviousPageLoading" 
        [ngTemplateOutlet]="spinner"
        [ngTemplateOutletContext]="{isVisible: isPreviousPageLoading}"
      ></ng-container>

      <ng-container *ngFor="let sequence of sequences; let idx=index; trackBy: trackBySequenceId">
        <pxc-sequence-preview 
          id="seq-{{idx}}"
          [attr.data-testid]="'seq-'+sequence.sequenceInstance.id"
          [sequence]="sequence"
          [actions]="actions"
          [patientRedirect]="patientRedirect"
          (mediaClicked)="mediaClicked($event)"
          class="animate-in fade-in slide-in-from-top-1"
          [hidePatient]="hidePatient"
        >
          <ng-container>
            <ng-container
              *ngIf="context === SequenceContext.SEQUENCE && sequence.sequenceInstance.telemonitoringId"
              [ngTemplateOutlet]="quickLink"
              [ngTemplateOutletContext]="{
                routerLink: ['/dashboard/telemonitorings', sequence.sequenceInstance.telemonitoringId],
                queryParams: { 'tab': null, 'cc': sequence.clientCode},
                title: 'Télésuivi',
                testid: 'telemonitoring-button'
              }"
            ></ng-container>
            <ng-container
              *ngIf="sequence.sequenceInstance.sadmEntityId"
              [ngTemplateOutlet]="quickLink"
              [ngTemplateOutletContext]="{
                routerLink: ['/dashboard/sadms', sequence.sequenceInstance.sadmEntityId],
                queryParams: {'tab': null, 'sequenceId': sequence.sequenceInstance.id },
                title: 'Analyse',
                testid: 'sadm-button'
              }"
            ></ng-container>
            <ng-container *ngIf="sequence.sequenceInstance.patientFormAnswerId 
              && sequence.sequenceInstance.creatorType === CreatorType.BY_PATIENT"
            >
              <button
                *tuiLet="sequence.sequenceInstance.riskIndicatorsCount as riskIndicatorsCount"
                tuiLink
                data-appearance="primary"
                class="flex items-center gap-1.5"
                (click)="openPatientFormAnswer(sequence)"
                [tuiHint]="riskIndicatorsCount > 0 ? riskIndicatorHint : null"
                tuiHintAppearance="error"
              >
                Questionnaire
                <ng-container
                  *ngIf="riskIndicatorsCount > 0"
                  [ngTemplateOutlet]="riskIndicatorBadge"
                ></ng-container>
                <ng-template #riskIndicatorBadge>
                  <tui-badge 
                    [value]="riskIndicatorsCount" 
                    class="ml-0.5" 
                    status="error"
                  >
                    <tui-svg src="tuiIconBell"></tui-svg>
                  </tui-badge>
                </ng-template>
                <ng-template #riskIndicatorHint>
                  Ce questionnaire contient {{riskIndicatorsCount}} indicateur(s) de risque
                </ng-template>
              </button>
            </ng-container>
          </ng-container>
        </pxc-sequence-preview>
      </ng-container>

    <ng-container
      [ngTemplateOutlet]="spinner"
      [ngTemplateOutletContext]="{isVisible: isNextPageLoading}"
    ></ng-container>

    </div>
  </tui-scrollbar>
</ng-container>

<ng-template #quickLink let-routerLink="routerLink" let-title="title" let-queryParams="queryParams" let-testid="testid">
  <a
    tuiLink
    [routerLink]="routerLink"
    [queryParams]="queryParams"
    queryParamsHandling="merge"
    [attr.data-testid]="testid"
    data-appearance="primary"
    class="flex items-center gap-1.5"
  >
    {{title}}
  </a>
</ng-template>

<ng-template #noSequenceBlock>
  <pxc-lottie-label
    animation="sequenceEmpty"
    message="Vous n'avez aucune photo"
    data-testid="no-sequence-message"
  ></pxc-lottie-label>
</ng-template>

<ng-template #spinner let-isVisible="isVisible">
  <div class="flex items-center justify-center py-2" data-testid="spinner">
    <pxc-spinner [isVisible]="isVisible"></pxc-spinner>
  </div>
</ng-template>