<pxc-tile *ngIf="!!channel" class="cursor-pointer px-8 py-2">

    <tui-svg leading src="tuiIconBellLarge"></tui-svg>

    <p title>Notifications</p>

    <tui-multi-select
        trailing
        [editable]="false"
        [formControl]="formControl"
        [valueContent]="valueContent"
        [tuiTextfieldLabelOutside]="true"
        class="w-full max-w-full"
        tuiTextfieldSize="s"
    >
        Aucune
        <tui-data-list-wrapper
            *tuiDataList
            tuiMultiSelectGroup
            [items]="items"
            [itemContent]="stringify"
        ></tui-data-list-wrapper>
    </tui-multi-select>

</pxc-tile>