<!-- If nothing has loaded -->
<div *ngIf="(galleryModel.items$ | async) === (null)">
  <pxc-loader [dark]="true" class="loader" data-testid="items-loading"></pxc-loader>
</div>
  
<div *ngIf="galleryModel.items$ | async as items;">
  
  <!-- SLIDER mode -->
  <div *ngIf="galleryModel.config.mode === gMode.SLIDER"
    class="gallery-slider h-full select-none thumb"
  >
    <div class="image-wrapper flex justify-center items-center overflow-hidden">
      <ng-container *ngIf="galleryModel.config.sourceType === gSourceType.STRING_SRC; then image; else innerHtml"></ng-container>
      
      <!-- Simple IMG -->
      <ng-template #image>
        <ng-container *ngFor="let item of items; let idx = index">
          <ng-container *ngIf="isInLazyLoadRange(idx, items.length)">
            <pxc-zoom-container
              [hidden]="galleryModel.displayIdx !== idx || !pictureLoadedIdx[idx]"
              [enableOverflow]="true"
            >
              <img
                class="h-full object-contain pointer-events-none mx-auto"
                [src]="item.pictureSrc"
                [alt]="'image-' + idx"
                (load)="onPictureLoad(idx)"
                (error)="onPictureError(idx)"
                [attr.data-testid]="'image-'+idx"
              >
            </pxc-zoom-container>
          </ng-container>

        </ng-container>
        
        <!-- Image Loader -->
        <ng-container *ngIf="!pictureLoadedIdx[galleryModel.displayIdx]">
          <pxc-loader [dark]="true" class="loader" data-testid="image-loader">
          </pxc-loader>
        </ng-container>
      </ng-template>
      
      <!-- InnerHtml element -->
      <ng-template #innerHtml>
        <div class="w-96" [innerHTML]="items[galleryModel.displayIdx].innerHtml"></div>
      </ng-template>
      
    </div>
  
    <!-- Thumbnails display -->
    <div *ngIf="galleryModel.config.thumbnails"
      class="w-screen overflow-x-auto flex justify-center" data-testid="thumbnails"
    >
      <ng-container *ngFor="let item of items; let idx = index">
        <img
          class="thumbnail cursor-pointer rounded-xl transition duration-300"
          [ngClass]="{'brightness-30 hover:brightness-50': idx !== galleryModel.displayIdx}"
          [src]="item.thumbnailSrc"
          [alt]="'thumbnail-' + idx"
          (click)="galleryModel.navTo(idx)"
          [hidden]="!thumbnailLoadedIdx[idx]"
          (load)="onThumbnailLoad(idx)"
          [attr.data-testid]="'thumbnail-'+idx"
        >

        <!-- Thumbnail Loader -->
        <ng-container *ngIf="!thumbnailLoadedIdx[idx]">
          <pxc-loader
            class="thumbnail-loader"
            [dark]="true"
            [attr.data-testid]="'thumbnail-loading-'+idx"
          >
          </pxc-loader>
        </ng-container>
      </ng-container>
    </div>
  </div>
  
  <!-- COMPARISON Mode -->
  <div
    *ngIf="galleryModel.config.mode === gMode.COMPARISON"
    (wheel)="onWheel($event)"
    class="gallery-comparison flex flex-row overflow-y-auto w-screen h-screen items-center select-none"
    data-testid="comparison-scroll"
  >
    <ng-container *ngFor="let item of items; let idx = index">
  
      <div class="h-full min-w-[33%] relative">

        <pxc-zoom-container
          class="zoom-container min-w-[33%]"
          [zoomWheelCTRLBinding]="true"
        >
          <img
            class="h-full object-contain max-h-[97vh] pointer-events-none"
            [hidden]="!pictureLoadedIdx[idx]"
            [ngClass]="items.length < 3 ? 'image-'+items.length :'image-infinite'"
            [src]="item.pictureSrc"
            [alt]="'image-' + idx"
            (load)="onPictureLoad(idx)"
            (error)="onPictureError(idx)"
            [attr.data-testid]="'comparison-'+idx"
          >

        </pxc-zoom-container>

        <ng-template #insertionAnchors></ng-template> 

      </div>
      
      <pxc-loader *ngIf="!pictureLoadedIdx[idx]"
        [dark]="true" class="loader"
        [ngClass]="items.length < 3 ? 'image-'+items.length :'image-infinite'"
        [attr.data-testid]="'comparison-loading-'+idx"
      >
      </pxc-loader>
  
    </ng-container>
  </div>
</div>

<!-- Gallery navigation -->
<div *ngIf="galleryModel.config.nav && galleryModel.config.mode !== gMode.COMPARISON"
  class="absolute left-0 top-0 select-none"
>
  <div class="
    group cursor-pointer
    absolute top-[50vh] -translate-y-1/2
    bg-action-dark rounded-full
    left-2 p-3 flex justify-center items-center
    "
    (click)="galleryModel.navPrev()"
    data-testid="previous"
  >
    <tui-svg
      class="text-slate-300 group-hover:text-white !text-2xl"
      src="tuiIconChevronLeftLarge"
    ></tui-svg>
  </div>
  <div class="
    group cursor-pointer
    absolute top-[50vh] -translate-y-1/2
    bg-action-dark rounded-full
    -right-[99vw] p-4 flex justify-center items-center
    "
    (click)="galleryModel.navNext()"
    data-testid="next"
  >
    <tui-svg
      class="text-slate-300 group-hover:text-white !text-2xl"
      src="tuiIconChevronRightLarge"
    ></tui-svg>
  </div>
</div>

<!-- Action bar -->
<div class="
  absolute flex top-2 flex-col sm:flex-row-reverse !py-1 px-2
  bg-action-dark rounded-xl select-none
  right-3 gap-1
"
  tuiMode="onDark"
>
  <button
    tuiIconButton
    appearance="flat"
    icon="tuiIconCloseLarge"
    (click)="close()"
    data-testid="close-action"
  ></button>
  
  <ng-container *ngFor="let action of galleryModel.actions">
      <button
        tuiIconButton
        *ngIf="!action.condition || action.condition()"
        appearance="flat"
        [icon]="action.icon"
        (click)="action.execute()"
        [attr.data-testid]="'action-'+action.id"
      ></button>
    </ng-container>
</div>

<ng-template #insertionAnchor></ng-template>