import { createReducer, on } from '@ngrx/store';
import { sequencesActions } from './sequences.actions';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { telemonitoringsActions } from '../telemonitorings/telemonitorings.actions';
import { initialSequencesState, SequencesState } from './sequences.state';
import { StoreSequence } from '../../models/store/store-sequence';
import { getNextPagedCollection, getPreviousPagedCollection } from '../../models/helpers/pagination-helper';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';

const flattenedSequenceToStoreSequence = (flattenedSequence: FlattenedSequence): StoreSequence => ({
  ...flattenedSequence.sequenceInstance,
  medias: flattenedSequence.medias,
  clientCode: flattenedSequence.clientCode,
  labelIds: flattenedSequence.labels.map((label) => label.id),
});

const flattenedSequencesToStoreSequences = (
  flattenedSequences: FlattenedSequence[],
): { [sequenceId: number]: StoreSequence } => flattenedSequences.reduce((acc, flattenedSequence) => ({
  ...acc,
  [flattenedSequence.sequenceInstance.id]: flattenedSequenceToStoreSequence(flattenedSequence),
}), {});

const sequenceSortByCreatedOn = (s1: StoreSequence, s2: StoreSequence): number =>
  (s2?.createdOn.getTime() ?? 0) - (s1?.createdOn.getTime() ?? 0);

const sequenceSort = (sequenceId1: number, sequenceId2: number, sequences: { [sequenceId: number]: StoreSequence }) =>
  sequenceSortByCreatedOn(sequences[sequenceId1], sequences[sequenceId2]);

export const sequencesReducer = createReducer(
  initialSequencesState,

  on(lifeCycleActions.resetState, sequencesActions.resetSequencesState, () => initialSequencesState),

  on(sequencesActions.setSynchronizeSequenceOngoing, (state, { synchronizeSequenceOnGoing }): SequencesState => ({
    ...state,
    synchronizeSequenceOnGoing,
  })),

  on(sequencesActions.updateFlattenedSequenceSuccess,
    (state, { sequence }): SequencesState => {

      const sequenceId = sequence.sequenceInstance.id;

      return {
        ...state,
        sequences: {
          ...state.sequences,
          data: {
            ...state.sequences.data,
            [sequenceId]: {
              ...state.sequences.data?.[sequenceId],
              ...flattenedSequenceToStoreSequence(sequence),
            },
          },
          sorted: [
            ...state.sequences.sorted,
            ...(state.sequences.sorted.indexOf(sequenceId) < 0 ? [sequenceId] : []),
          ].sort((sequenceId1, sequenceId2) => sequenceSort(sequenceId1, sequenceId2, state.sequences.data)),
        },
      };
    },
  ),

  on(sequencesActions.updateSequenceSuccess,
    (state, { updatedSequence }): SequencesState =>
      ({
        ...state,
        sequences: {
          ...state.sequences,
          data: {
            ...state.sequences.data,
            [updatedSequence.id]: {
              ...state.sequences.data[updatedSequence.id],
              ...updatedSequence,
            },
          },
          sorted: [
            ...state.sequences.sorted,
            ...(state.sequences.sorted.indexOf(updatedSequence.id) < 0 ? [updatedSequence.id] : []),
          ].sort((sequenceId1, sequenceId2) => sequenceSort(sequenceId1, sequenceId2, state.sequences.data)),
        },
      }),
  ),

  on(
    sequencesActions.getSequencesSuccess,
    (state, { collection }): SequencesState => {

      if (!collection.data?.length) {
        return state;
      }

      const data = flattenedSequencesToStoreSequences(collection.data);
      return {
        ...state,
        sequences: {
          totalCount: collection.data.length,
          context: initialSequencesState.sequences.context,
          data,
          navigation: initialSequencesState.sequences.navigation,
          sorted: collection.data.map((fSequence) => fSequence.sequenceInstance.id)
            .sort((sId1, sId2) => sequenceSort(sId1, sId2, data)),
        },
      };
    },
  ),

  on(
    sequencesActions.getNextUserSequences,
    (state, { options: { reset } }): SequencesState => ({
      ...state,
      sequences: {
        ...state.sequences,
        context: {
          ...state.sequences.context,
          isNextPageLoading: true,
        },
        ...(reset && { sorted: [], data: null }),
      },
      ...(reset && { isLoaded: false }),
    }),
  ),

  on(
    sequencesActions.getPreviousUserSequences,
    (state, { options: { reset } }): SequencesState => ({
      ...state,
      sequences: {
        ...state.sequences,
        context: {
          ...state.sequences.context,
          isPreviousPageLoading: true,
        },
        ...(reset && { data: null }),
      },
      ...(reset && { isLoaded: false }),
    }),
  ),

  on(
    sequencesActions.getNextUserSequencesSuccess,
    (state, { reset, collection }): SequencesState => ({
      ...state,
      isLoaded: true,
      sequences: getNextPagedCollection({
        currentCollection: state.sequences, //  PagedCollection<{ [sequenceId: number]: StoreSequence }>;
        nextCollection: collection, // PagedResponse<FlattenedSequence>
      }, {
        reset,
        truncate: true,
        dataReducer: flattenedSequencesToStoreSequences,
        dataIdentifier: 'sequenceInstance.id',
      }),
    }),
  ),

  on(
    sequencesActions.getPreviousUserSequencesSuccess,
    (state, { collection }): SequencesState => ({
      ...state,
      sequences: getPreviousPagedCollection({
        currentCollection: state.sequences,
        nextCollection: collection,
      }, {
        dataReducer: flattenedSequencesToStoreSequences,
        dataIdentifier: 'sequenceInstance.id',
      }),
    }),
  ),

  on(
    sequencesActions.loadSequences,
    (state, { data }): SequencesState => ({
      ...state,
      sequences: {
        ...state.sequences,
        data: {
          ...state.sequences.data,
          ...flattenedSequencesToStoreSequences(data),
        },
      },
    }),
  ),

  on(
    sequencesActions.updateSequenceMediaSuccess,
    (state, { sequenceId, updatedMedia }): SequencesState => {
      if (state.sequences.data) {
        return {
          ...state,
          sequences: {
            ...state.sequences,
            data: {
              ...state.sequences.data,
              [sequenceId] : {
                ...state.sequences.data[sequenceId],
                medias: (state.sequences.data[sequenceId] || {}).medias?.map((media) => media.id === updatedMedia.id
                  ? updatedMedia
                  : media,
                ),
              },
            },
          },
        };
      } else {
        return state;
      }
    },
  ),

  on(sequencesActions.synchronizeSequence, (state): SequencesState => ({
    ...state,
    synchronizeSequenceOnGoing: true,
  })),
  on(sequencesActions.synchronizeSequenceError, (state): SequencesState => ({
    ...state,
    synchronizeSequenceOnGoing: false,
  })),

  on(sequencesActions.synchronizeSequenceSuccess, (state, { sequence }): SequencesState => {
    const newState = {
      ...state,
      synchronizeSequenceOnGoing: false,
      sequences: {
        ...state.sequences,
        data: {
          ...state.sequences.data,
          [sequence.sequenceInstance.id]: flattenedSequenceToStoreSequence(sequence),
        },
      },
    };

    return {
      ...newState,
      sequences: {
        ...newState.sequences,
        sorted: [
          sequence.sequenceInstance.id,
          ...state.sequences.sorted,
        ].sort((sequenceId1, sequenceId2) => sequenceSort(sequenceId1, sequenceId2, newState.sequences.data)),
      },
    };

  }),

  on(
    sequencesActions.addSequenceMediaSuccess,
    (state, { sequenceId, updatedMedia }): SequencesState => ({
      ...state,
      sequences: {
        ...state.sequences,
        data: {
          ...state.sequences.data,
          [sequenceId]: {
            ...state.sequences.data[sequenceId],
            medias: [
              ...state.sequences.data[sequenceId].medias,
              updatedMedia,
            ],
          },
        },
      },
    }),
  ),

  on(
    sequencesActions.deleteSequenceSuccess,
    (state, { sequenceId }): SequencesState => {

      const { [sequenceId]: deletedSequence, ...data } = state.sequences.data;

      return {
        ...state,
        sequences: {
          ...state.sequences,
          data,
          sorted: state.sequences.sorted.filter((sId) => sequenceId !== sId),
        },
      };
    },
  ),

  on(sequencesActions.attachSequenceMediasSuccess,
    (state, { updatedSequence }): SequencesState => ({
      ...state,
      sequences: {
        ...state.sequences,
        data: {
          ...state.sequences.data,
          [updatedSequence.sequenceInstance.id] : {
            ...state.sequences.data[updatedSequence.sequenceInstance.id],
            medias: updatedSequence.medias,
          },
        },
      },
    }),
  ),

  on(sequencesActions.deleteSequenceMediaSuccess,
    (state, { sequenceId, mediaId }): SequencesState => ({
      ...state,
      sequences: {
        ...state.sequences,
        data: {
          ...state.sequences.data,
          [sequenceId] : {
            ...state.sequences.data[sequenceId],
            medias: state.sequences.data[sequenceId].medias.filter((media) => media.id !== mediaId),
          },
        },
      },
    }),
  ),

  on(telemonitoringsActions.addTelemonitoring,
    (state, { telemonitoring, sequenceIds }): SequencesState => ({
      ...state,
      sequences: {
        ...state.sequences,
        data: {
          ...state.sequences.data,
          ...(sequenceIds.reduce((acc, sequenceId) => ({
            ...acc,
            [sequenceId]: {
              ...state.sequences.data[sequenceId],
              telemonitoringId: telemonitoring.id,
            },
          }), {})),
        },
      },
    }),
  ),

  on(telemonitoringsActions.deleteTelemonitoringSuccess,
    (state, { telemonitoringId }): SequencesState => ({
      ...state,
      sequences: {
        ...state.sequences,
        data: Object.values(state.sequences.data)
          .filter((sequence) =>
            !((sequence.telemonitoringId === telemonitoringId)
            && !state.sequences.sorted.includes(sequence.id)))
          .reduce((acc, sequence) => ({
            ...acc,
            [sequence.id]: state.sequences.data[sequence.id],
          }), {},
          ),
      },
    }),
  ),

  on(sequencesActions.deleteSadmEntityFromSequences, (state, { sadmEntityId }): SequencesState => ({
    ...state,
    sequences: {
      ...state.sequences,
      data: Object.values(state.sequences.data)
        .reduce((acc, sequence) => ({
          ...acc,
          [sequence.id]: {
            ...state.sequences.data[sequence.id],
            sadmEntityId: (sequence.sadmEntityId === sadmEntityId) ? null : sequence.sadmEntityId,
          },
        }), {}),
    },
  })),
);
