import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component,
  EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { AvatarStyle, ChannelMessage, ChannelUser, ChannelMessageSenderType } from '@pixacare/pxc-ts-core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AvatarStyleService } from 'src/app/services/avatar-style.service';
import { AvatarComponent } from 'src/app/shared/components/avatar/avatar.component';
import { timestampToDate } from '../chat.utils';
import { TuiElasticContainerModule } from '@taiga-ui/kit';
import { ListMessageReadersPipe } from 'src/app/shared/pipes/list-message-readers.pipe';
import { MessageSpecialComponent } from '../message-special/message-special.component';

@Component({
  selector: 'pxc-message-bubble',
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    TuiElasticContainerModule,
    ListMessageReadersPipe,
    MessageSpecialComponent,
  ],
  templateUrl: './message-bubble.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    ':host { display: flex; width: 100%; }',
  ],
})
export class MessageBubbleComponent implements OnChanges {

  @Input() message: ChannelMessage;
  @Input() readers: ChannelUser[];
  @Input() showHeader = true;
  @Input() channelUsersCount: number;
  @Output() messageClicked = new EventEmitter<void>();

  isIncoming: boolean;
  date: Date;
  shouldShowReaders = false;

  readonly authenticationService = inject(AuthenticationService);
  private readonly avatarStyleService = inject(AvatarStyleService);

  get avatarColors(): AvatarStyle {
    switch (this.message.senderType) {
      case ChannelMessageSenderType.SYSTEM:
        return this.avatarStyleService.system();
      case ChannelMessageSenderType.ALERT:
        return this.avatarStyleService.alert();
      default:
        return this.avatarStyleService.getAvatarColors(this.message.senderName);
    }
  }

  ngOnChanges({ readers, channelUsersCount }: SimpleChanges) {

    this.isIncoming = this.message.senderId !== this.authenticationService.currentUser.id;
    this.date = timestampToDate(this.message.createdOn);

    if (!!readers || !!channelUsersCount) {
      const isCurrentUserSender = this.message.senderId === this.authenticationService.currentUser.id;
      const hasReaders = readers?.currentValue?.length > 0;
      const isGroupChat = this.channelUsersCount > 2;

      this.shouldShowReaders = this.readers !== null
        && (isCurrentUserSender
          || hasReaders
          || (isGroupChat && hasReaders)
        );

    }
  }

}
