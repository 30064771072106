import { createReducer, on } from '@ngrx/store';
import { FlattenedTelemonitoring, Telemonitoring, FlattenedSequence } from '@pixacare/pxc-ts-core';
import { getNextPagedCollection } from '@shared/models/helpers/pagination-helper';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { sequencesActions } from '../sequences/sequences.actions';
import { telemonitoringsActions } from './telemonitorings.actions';
import { TelemonitoringsState, initialTelemonitoringsState } from './telemonitorings.state';


const flattenedTelemonitoringToTelemonitoring = (
  fTelemonitoring: FlattenedTelemonitoring,
): Telemonitoring => ({
  ...fTelemonitoring.telemonitoring,
  sequenceIds: fTelemonitoring.sequences?.map((sequence) => sequence.sequenceInstance.id) || null,
  thumbnailUri: fTelemonitoring.thumbnailUri,
});

const flattenedTelemonitoringsToTelemonitorings = (
  fTelemonitorings: FlattenedTelemonitoring[],
): { [telemonitoringId: number]: Telemonitoring } => (
  fTelemonitorings.reduce((acc, fTelemonitoring) => ({
    ...acc,
    [fTelemonitoring.telemonitoring.id]: flattenedTelemonitoringToTelemonitoring(fTelemonitoring),
  }), {})
);

const addSequenceIds = (
  state: TelemonitoringsState,
  telemonitoringId: number,
  sequenceIds: number[],
): TelemonitoringsState => ({
  ...state,
  telemonitorings: {
    ...state.telemonitorings,
    data: {
      ...state.telemonitorings.data,
      [telemonitoringId]: {
        ...state.telemonitorings.data[telemonitoringId],
        sequenceIds: [
          ...state.telemonitorings.data[telemonitoringId].sequenceIds,
          ...sequenceIds,
        ],
        lastActivity: new Date(Date.now()),
      },
    },
  },
});


const getThumbnailFromSequences = (sequences: FlattenedSequence[]): string => {
  const sortedSequences = [...sequences]
    .sort((a, b) => b.sequenceInstance.createdOn.getTime() - a.sequenceInstance.createdOn.getTime());
  return sortedSequences[0]?.medias[0]?.thumbnailUri || null;
};


export const telemonitoringsReducer = createReducer(
  initialTelemonitoringsState,

  on(lifeCycleActions.resetState, telemonitoringsActions.resetTelemonitoringsState, () => initialTelemonitoringsState),

  on(telemonitoringsActions.getNextUserTelemonitorings, (state, { options: { reset } }) => ({
    ...state,
    telemonitorings: {
      ...state.telemonitorings,
      context: {
        ...state.telemonitorings.context,
        isNextPageLoading: true,
      },
      ...(reset && { sorted: [], data: null }),
    },
    ...(reset && { isLoaded: false }),
  })),

  on(
    telemonitoringsActions.getNextUserTelemonitoringsSuccess,
    (state, { reset, collection }): TelemonitoringsState => ({
      ...state,
      telemonitorings: getNextPagedCollection({
        currentCollection: state.telemonitorings,
        nextCollection: collection,
      }, {
        reset,
        sortPredicate: (entityId1, entityId2, entities) => (
          entities[entityId2].lastActivity?.getTime() - entities[entityId1].lastActivity?.getTime()
        ),
        dataReducer: flattenedTelemonitoringsToTelemonitorings,
        dataIdentifier: 'telemonitoring.id',
      }),
      isLoaded: true,
    }),
  ),

  on(telemonitoringsActions.addTelemonitoring, (state: TelemonitoringsState, { telemonitoring }) => ({
    ...state,
    telemonitorings: {
      ...state.telemonitorings,
      data: {
        ...state.telemonitorings.data,
        [telemonitoring.id]: telemonitoring,
      },
      sorted: [
        telemonitoring.id,
        ...state.telemonitorings.sorted,
      ],
    },
  })),

  on(telemonitoringsActions.getTelemonitoring, (state: TelemonitoringsState, { }) => ({
    ...state,
    isLoaded: false,
  })),

  on(telemonitoringsActions.getTelemonitoringSuccess, (state: TelemonitoringsState, { telemonitoring }) => ({
    ...state,
    isLoaded: true,
    telemonitorings: {
      ...state.telemonitorings,
      data: {
        ...state.telemonitorings.data,
        [telemonitoring.telemonitoring.id]: flattenedTelemonitoringToTelemonitoring(telemonitoring),
      },
    },
  })),

  on(telemonitoringsActions.deleteTelemonitoringSuccess, (state: TelemonitoringsState, { telemonitoringId }) => ({
    ...state,
    telemonitorings: {
      ...state.telemonitorings,
      data: Object.values(state.telemonitorings.data)
        .filter((tlm) => tlm.id !== telemonitoringId)
        .reduce((acc, tlm) => ({
          ...acc,
          [tlm.id]:tlm,
        }), {}),
      sorted: [
        ...state.telemonitorings.sorted.filter((id) => telemonitoringId !== id),
      ],
    },
  })),

  on(telemonitoringsActions.updateTelemonitoringPatient,
    (state: TelemonitoringsState, { patientId, targetPatientId }) => ({
      ...state,
      telemonitorings: {
        ...state.telemonitorings,
        data: {
          ...state.telemonitorings.data,
          ...(Object.values(state.telemonitorings.data)
            .filter((telemonitoring) => telemonitoring.patientId === patientId)
            .map((telemonitoring) => ({
              ...telemonitoring,
              patientId: targetPatientId,
            }))
            .reduce((acc, telemonitoring) => ({
              ...acc,
              [telemonitoring.id]: telemonitoring,
            }), {})
          ),
        },
      },
    }),
  ),

  on(telemonitoringsActions.updateTelemonitoringActiveStateSuccess,
    (state: TelemonitoringsState, { telemonitoringId, isActive }) => ({
      ...state,
      telemonitorings: {
        ...state.telemonitorings,
        data: {
          ...state.telemonitorings.data,
          [telemonitoringId]: {
            ...state.telemonitorings.data[telemonitoringId],
            isActive,
          },
        },
      },
    }),
  ),

  on(sequencesActions.synchronizeSequenceSuccess,
    (state: TelemonitoringsState, { sequence }) => {

      if (!sequence.sequenceInstance.telemonitoringId) {
        return state;
      }

      return addSequenceIds(state, sequence.sequenceInstance.telemonitoringId, [sequence.sequenceInstance.id]);

    },
  ),

  on(telemonitoringsActions.loadTelemonitoringSequences,
    (state: TelemonitoringsState, { tmId, sequences }) => ({
      ...state,
      telemonitorings: {
        ...state.telemonitorings,
        data: {
          ...state.telemonitorings.data,
          [tmId]: {
            ...state.telemonitorings.data[tmId],
            sequenceIds: sequences.map((sequence) => sequence.sequenceInstance.id),
            thumbnailUri: state.telemonitorings.data[tmId].thumbnailUri ?? getThumbnailFromSequences(sequences),
          },
        },
      },
    })),

  on(telemonitoringsActions.linkSequencesSuccess,
    (state: TelemonitoringsState, { telemonitoringId, sequenceIds }) =>
      addSequenceIds(state, telemonitoringId, sequenceIds),
  ),

  on(telemonitoringsActions.unlinkSequenceSuccess,
    (state: TelemonitoringsState, { telemonitoringId, sequenceId }) => ({
      ...state,
      telemonitorings: {
        ...state.telemonitorings,
        data: {
          ...state.telemonitorings.data,
          [telemonitoringId]: {
            ...state.telemonitorings.data[telemonitoringId],
            sequenceIds: state.telemonitorings.data[telemonitoringId].sequenceIds
              .filter((id) => id !== sequenceId),
            lastActivity: new Date(Date.now()),
          },
        },
      },
    }),
  ),

);
