/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@modules/shared/shared.module';
import { Store } from '@ngrx/store';
import { ClientUser, SearchedUser } from '@pixacare/pxc-ts-core';
import { AuthenticationService } from '@services/authentication.service';
import { UserCardComponent } from '@shared/components/user-card/user-card.component';
import { UserInputComponent } from '@shared/components/user-input/user-input.component';
import { UserInputSelection } from '@shared/models/helpers/user-input-selection';
import { selectClientCode } from '@shared/store/router/router.selectors';
import { selectEnabledDepartmentUsers, selectUsers } from '@shared/store/users/users.selectors';
import { TUI_DEFAULT_MATCHER } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiScrollbarModule } from '@taiga-ui/core';
import { combineLatest, map, Observable, shareReplay, switchMap } from 'rxjs';

type OnChangeFn = (value: UserInputSelection[]) => void;

@Component({
  selector: 'pxc-telemonitoring-collaborators',
  standalone: true,
  imports: [
    CommonModule,
    UserInputComponent,
    UserCardComponent,
    TuiScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    TuiButtonModule,
    SharedModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TelemonitoringCollaboratorsComponent,
    },
  ],
  templateUrl: './telemonitoring-collaborators.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringCollaboratorsComponent implements OnChanges, ControlValueAccessor {

  @Input() departmentIds: number[] = [];
  @Input() placeholder = 'Ajoutez un utilisateur';

  private readonly store = inject(Store);
  readonly currentUserId = inject(AuthenticationService).currentUser.id;

  users$ = this.store.select(selectClientCode).pipe(
    switchMap((cc: string) => this.store.select(selectUsers).pipe(
      map((users) => users[cc]),
    )),
  );

  searchableUsers$: Observable<SearchedUser[]>;
  value: UserInputSelection[] = [];
  disabled = false;
  touched = false;
  excludedIds = [this.currentUserId];
  userInputValue = [];

  onChange: OnChangeFn = () => {};
  onTouched: () => void = () => {};

  bindedSearchUsers = this.searchUsers.bind(this);

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.departmentIds) {
      return;
    }

    this.searchableUsers$ = this.store.select(selectClientCode).pipe(
      switchMap((cc: string) => combineLatest(
        this.departmentIds.map((departmentId) => this.store.select(selectEnabledDepartmentUsers(cc, departmentId))),
      ).pipe(
        map((users) => users.flat().map(this.clientUserToSearchedUser)),
        shareReplay(1),
      )),
    );
  }

  clientUserToSearchedUser(user: ClientUser): SearchedUser {

    if (!user) {
      return null;
    }

    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      liveId: user.liveId,
      clientNames: [],
      medicalSpecialtyLabel: user.mailAddress,
    } as SearchedUser;
  }

  searchUsers(search: string): Observable<SearchedUser[]> {
    return this.searchableUsers$.pipe(
      map((users) => users.filter((user) =>
        TUI_DEFAULT_MATCHER(user, search, (u) => `${u.firstName} ${u.lastName} ${u.medicalSpecialtyLabel}`)),
      ),
    );
  }

  writeValue(obj: UserInputSelection[]): void {
    this.value = obj;
    this.updateExcludedIds();
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateExcludedIds(): void {
    this.excludedIds = [this.currentUserId, ...this.value.map(({ user }) => user.id)];
  }

  onSelectChange(value: UserInputSelection[]): void {
    this.touched = true;
    this.value.push(...value);
    this.userInputValue = [];
    this.updateExcludedIds();
    this.onTouched();
    this.onChange(this.value);
  }

  removeUser(idx: number): void {
    this.touched = true;
    this.value.splice(idx, 1);
    this.updateExcludedIds();
    this.onTouched();
    this.onChange(this.value);
  }

}
