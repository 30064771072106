import { createReducer, on } from '@ngrx/store';
import { SadmEntity } from '@pixacare/pxc-ts-core';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { sadmActions } from './sadm.actions';
import { initialSadmState, SadmState } from './sadm.state';
import { toArchivable } from '../archivable.adapter';

const responseToStoreEntity = (sadmEntity: SadmEntity): SadmEntity => {
  const sequencesIds = sadmEntity.sequences?.map((sequence) => sequence.sequenceInstance.id);
  const newSadmEntity = { ...sadmEntity };
  delete newSadmEntity.sequences;
  delete newSadmEntity.createdByInstance;
  delete newSadmEntity.sadmClient;

  return {
    ...newSadmEntity,
    ...(sequencesIds && { sequencesIds }),
    protocolsHistory: [...(sadmEntity.protocolsHistory ?? [])]
      .sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime()),
  };
};

export const sadmReducer = createReducer(
  initialSadmState,

  on(lifeCycleActions.resetState, () => initialSadmState),

  on(sadmActions.getSadmClientsSuccess,
    (state: SadmState, { sadmClients, override }) => ({
      ...state,
      clients: sadmClients.reduce((acc, sadmClient) => (
        !override && acc[sadmClient.id]
          ? acc
          : {
            ...acc,
            [sadmClient.id]: sadmClient,
          }
      ), state.clients),
      userSadmClients: override ? sadmClients.map((sadmClient) => sadmClient.id) : state.userSadmClients,
    }),
  ),

  on(sadmActions.getSadmEntitiesSuccess,
    (state: SadmState, { clientCode, patientId, sadmEntities }) => ({
      ...state,
      entities: sadmEntities.reduce((acc, sadmEntity) => ({
        ...acc,
        [sadmEntity.id]: {
          ...state.entities[sadmEntity.id],
          ...responseToStoreEntity(sadmEntity),
        },
      }), state.entities),
      loadState: {
        ...state.loadState,
        [clientCode]: {
          ...state.loadState[clientCode],
          [patientId]: true,
        },
      },
    }),
  ),

  on(
    sadmActions.getSadmEntitySuccess,
    sadmActions.updateSadmEntitySuccess,
    (state: SadmState, { sadmEntity }) => ({
      ...state,
      entities: {
        ...state.entities,
        [sadmEntity.id]: {
          ...state.entities[sadmEntity.id],
          ...responseToStoreEntity(sadmEntity),
        },
      },
    }),
  ),

  on(sadmActions.getSadmDashboardSuccess,
    (state: SadmState, { sadmDashboard, sadmEntityId }) => ({
      ...state,
      dashboards: {
        ...state.dashboards,
        [sadmEntityId]: {
          ...sadmDashboard,
          protocolReports: [...(sadmDashboard.protocolReports ?? [])]
            .sort((a, b) => b.date.getTime() - a.date.getTime()),
          sadmEntity: undefined,
        },
      },
    }),
  ),

  on(sadmActions.unloadSadmEntity,
    (state: SadmState, { sadmEntityId }) => {

      const entities = { ...state.entities };
      delete entities[sadmEntityId];

      return { ...state, entities };

    }),

  on(sadmActions.unloadSadmDashboard, (state: SadmState, { sadmEntityId }) => ({
    ...state,
    dashboards: {
      ...state.dashboards,
      [sadmEntityId]: null,
    },
  })),

  on(
    sadmActions.archiveSadmEntitySuccess,
    sadmActions.unarchiveSadmEntitySuccess,
    (state: SadmState, { sadmEntity }) => ({
      ...state,
      entities: {
        ...state.entities,
        [sadmEntity.id]: {
          ...state.entities[sadmEntity.id],
          ...toArchivable(sadmEntity),
        },
      },
    }),
  ),

);


